import "core-js/modules/es7.object.get-own-property-descriptors.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.array.iterator.js";
import "core-js/modules/es6.object.keys.js";
import _defineProperty from "/app/node_modules/@babel/runtime-corejs2/helpers/defineProperty";
import "core-js/modules/es6.regexp.to-string.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.function.name.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import axios from 'axios';
import swal from 'sweetalert';
import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';
import { userTour } from "./../util/driver";
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCDZDcziXpbMoxm-RHtaxzpGLpy4UW_Upg',
    libraries: 'places'
  },
  installComponents: true
});
export default {
  name: 'Device',
  data: function data() {
    return {
      isPwd: false,
      modelAddUser: {},
      modelPriceSet: {},
      dataPriced: {},
      dataPriced2: {},
      clickedLocation: '',
      dialogAdd: false,
      dialogEdit: false,
      host: '',
      filter: '',
      pagination: {
        rowsPerPage: 10
      },
      loading: true,
      columns: [{
        name: 'id',
        required: true,
        label: 'User ID',
        align: 'center',
        field: function field(row) {
          return row.id;
        },
        format: function format(val) {
          return "".concat(val);
        },
        headerStyle: 'font-weight: bold;',
        headerClasses: 'bg-light-green-2 ellipsis',
        sortable: true
      }, {
        name: 'name',
        align: 'left',
        label: 'Nama',
        field: 'name',
        headerStyle: 'font-weight: bold;',
        headerClasses: 'bg-light-green-2 ellipsis',
        sortable: true
      }, {
        name: 'email',
        align: 'left',
        label: 'Email',
        field: 'email',
        headerStyle: 'font-weight: bold;',
        headerClasses: 'bg-light-green-2 ellipsis',
        sortable: true
      }, {
        name: 'phone',
        align: 'left',
        label: 'Nomor HP',
        field: 'phone',
        headerStyle: 'font-weight: bold;',
        headerClasses: 'bg-light-green-2 ellipsis',
        sortable: true
      }, {
        name: 'role',
        align: 'left',
        label: 'Peran',
        field: 'role',
        headerStyle: 'font-weight: bold;',
        headerClasses: 'bg-light-green-2 ellipsis',
        sortable: true
      }, {
        name: 'created_at',
        align: 'left',
        label: 'Waktu',
        field: 'createdAt',
        headerStyle: 'font-weight: bold;',
        headerClasses: 'bg-light-green-2 ellipsis',
        sortable: true
      }, {
        name: 'Action',
        headerStyle: 'font-weight: bold;',
        headerClasses: 'bg-light-green-2 ellipsis',
        label: 'Aksi'
      }],
      data2: [],
      data: {},
      selected: {},
      responseData: {},
      location: null,
      latitude: '',
      longitude: '',
      gettingLocation: false,
      errorStr: null,
      getDataInterval: null,
      center: {
        lat: 0,
        lng: 0
      },
      markers: [{
        position: {
          lat: 0,
          lng: 0
        }
      }],
      listdevice: [],
      options: this.listdevice,
      devices: []
    };
  },
  computed: {
    ConfirmPWD: function ConfirmPWD() {
      var _this = this;

      return [function (v) {
        return !!v || 'Saisissez quelquechose :-)';
      }, function (v) {
        return v !== _this.$refs.fldPasswordChange.value || 'Mots de passe différents';
      }];
    }
  },
  created: function created() {},
  watch: {
    selected: {
      immediate: true,
      deep: true,
      handler: function handler() {}
    }
  },
  mounted: function mounted() {
    var _this2 = this;

    this.host = this.$store.state.global.host;
    this.getData();
    this.getDataInterval = setInterval(function () {
      _this2.getData();
    }, 180000);
    this.getDevice();
  },
  destroyed: function destroyed() {
    if (this.getDataInterval) {
      clearInterval(this.getDataInterval);
    }
  },
  methods: {
    startTour: function startTour() {
      userTour();
    },
    getDevice: function getDevice() {
      var _this3 = this;

      axios.get(this.host + '/dashboard/device/select', {
        'headers': {
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (res) {
        _this3.listdevice = res.data;

        _this3.listdevice.sort(function (a, b) {
          return a.name.localeCompare(b.name);
        });
      }).catch(function (err) {
        if (err.response.status === 401) {
          _this3.$cookie.delete('token');

          _this3.$router.push('/login');
        }
      });
    },
    filterDevice: function filterDevice(val, update) {
      var _this4 = this;

      if (val === '') {
        update(function () {
          _this4.options = _this4.listdevice;
        });
        return;
      }

      update(function () {
        var needle = val.toString().toLowerCase();
        _this4.options = _this4.options.filter(function (v) {
          return v.name.toString().toLowerCase().indexOf(needle) > -1;
        });
      });
    },
    onRowClick: function onRowClick(row) {
      var _this5 = this;

      this.$q.loading.show();
      axios.get(this.host + '/dashboard/user/' + row.id, {
        'headers': {
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (res) {
        _this5.$q.loading.hide();

        _this5.selected = res.data;
      }).catch(function (err) {
        _this5.$q.loading.hide();

        if (err.response.status === 401) {
          _this5.$cookie.delete('token');

          _this5.$router.push('/login');
        }
      });
      this.dialogEdit = true;
    },
    getData: function getData() {
      var _this6 = this;

      this.loading = true;
      axios.get(this.host + '/dashboard/user', {
        'headers': {
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (res) {
        _this6.data2 = res.data;
        _this6.loading = false;
      }).catch(function (err) {
        _this6.loading = false;

        if (err.response.status === 401) {
          _this6.$cookie.delete('token');

          _this6.$router.push('/login');
        }
      });
    },
    cancle: function cancle() {
      this.defaultValueAdd();
      this.dialogAdd = false;
    },
    defaultValueAdd: function defaultValueAdd() {
      this.modelAddUser = {};
    },
    save: function save() {
      var _this$modelAddUser$de,
          _this7 = this;

      this.$q.loading.show();
      axios({
        method: 'post',
        url: this.host + '/dashboard/user/',
        data: _objectSpread(_objectSpread({}, this.modelAddUser), {}, {
          devices: (_this$modelAddUser$de = this.modelAddUser.devices) === null || _this$modelAddUser$de === void 0 ? void 0 : _this$modelAddUser$de.map(function (listdevice) {
            return listdevice.id;
          })
        }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (response) {
        _this7.$q.loading.hide();

        _this7.dialogAdd = false;

        _this7.defaultValueAdd();

        _this7.$q.notify({
          type: 'positive',
          color: 'positive',
          icon: 'check',
          message: 'Success',
          position: 'top-right',
          classes: 'my-custom-font',
          caption: '',
          actions: [{
            label: 'Dismiss',
            color: 'white',
            handler: function handler() {
              /* ... */
            }
          }]
        });

        _this7.getData();
      }).catch(function (error) {
        if (error.response.data.message === '"name" is required') {
          _this7.$q.loading.hide();

          _this7.$q.notify({
            class: 'my-custom-font',
            color: 'negative',
            message: 'Error',
            icon: 'close',
            position: 'top-right',
            caption: 'Nama harus diisi !',
            classes: 'my-custom-font',
            actions: [{
              label: 'Dismiss',
              color: 'white',
              handler: function handler() {
                /* ... */
              }
            }]
          });
        } else if (error.response.data.message === '"name" is not allowed to be empty') {
          _this7.$q.loading.hide();

          _this7.$q.notify({
            color: 'negative',
            message: 'Error',
            icon: 'close',
            position: 'top-right',
            caption: 'Nama tidak boleh kosong !',
            classes: 'my-custom-font',
            actions: [{
              label: 'Dismiss',
              color: 'white',
              handler: function handler() {
                /* ... */
              }
            }]
          });
        } else if (error.response.data.message === '"email" must be a valid email') {
          _this7.$q.loading.hide();

          _this7.$q.notify({
            color: 'negative',
            message: 'Error',
            icon: 'close',
            position: 'top-right',
            caption: 'Email tidak valid !',
            classes: 'my-custom-font',
            actions: [{
              label: 'Dismiss',
              color: 'white',
              handler: function handler() {
                /* ... */
              }
            }]
          });
        } else if (error.response.data.message === '"role" is required') {
          _this7.$q.loading.hide();

          _this7.$q.notify({
            color: 'negative',
            message: 'Error',
            icon: 'close',
            position: 'top-right',
            caption: 'Role harus diisi !',
            classes: 'my-custom-font',
            actions: [{
              label: 'Dismiss',
              color: 'white',
              handler: function handler() {
                /* ... */
              }
            }]
          });
        } else if (error.response.data.message === '"phone" is not allowed to be empty') {
          _this7.$q.loading.hide();

          _this7.$q.notify({
            color: 'negative',
            message: 'Error',
            icon: 'close',
            position: 'top-right',
            caption: 'Nomor HP tidak boleh kosong !',
            classes: 'my-custom-font',
            actions: [{
              label: 'Dismiss',
              color: 'white',
              handler: function handler() {
                /* ... */
              }
            }]
          });
        } else if (error.response.data.message === '"phone" is required') {
          _this7.$q.loading.hide();

          _this7.$q.notify({
            color: 'negative',
            message: 'Error',
            icon: 'close',
            position: 'top-right',
            caption: 'Nomor HP harus diisi !',
            classes: 'my-custom-font',
            actions: [{
              label: 'Dismiss',
              color: 'white',
              handler: function handler() {
                /* ... */
              }
            }]
          });
        } else if (error.response.data.message === '"password" is not allowed to be empty') {
          _this7.$q.loading.hide();

          _this7.$q.notify({
            color: 'negative',
            message: 'Error',
            icon: 'close',
            position: 'top-right',
            caption: 'Password tidak boleh kosong !',
            classes: 'my-custom-font',
            actions: [{
              label: 'Dismiss',
              color: 'white',
              handler: function handler() {
                /* ... */
              }
            }]
          });
        } else if (error.response.data.message === '"password" length must be at least 6 characters long') {
          _this7.$q.loading.hide();

          _this7.$q.notify({
            color: 'negative',
            message: 'Error',
            icon: 'close',
            position: 'top-right',
            caption: 'Password harus memiliki minimal 6 karakter !',
            classes: 'my-custom-font',
            actions: [{
              label: 'Dismiss',
              color: 'white',
              handler: function handler() {
                /* ... */
              }
            }]
          });
        } else if (error.response.data.message === '"password" is required') {
          _this7.$q.loading.hide();

          _this7.$q.notify({
            color: 'negative',
            message: 'Error',
            icon: 'close',
            position: 'top-right',
            classes: 'my-custom-font',
            caption: 'Password harus diisi !',
            actions: [{
              label: 'Dismiss',
              color: 'white',
              handler: function handler() {
                /* ... */
              }
            }]
          });
        } else if (error.response.data.message === '"email" is not allowed to be empty') {
          _this7.$q.loading.hide();

          _this7.$q.notify({
            color: 'negative',
            message: 'Error',
            icon: 'close',
            position: 'top-right',
            caption: 'Email tidak boleh kosong !',
            classes: 'my-custom-font',
            actions: [{
              label: 'Dismiss',
              color: 'white',
              handler: function handler() {
                /* ... */
              }
            }]
          });
        } else if (error.response.data.message === '"email" is required') {
          _this7.$q.loading.hide();

          _this7.$q.notify({
            color: 'negative',
            message: 'Error',
            icon: 'close',
            position: 'top-right',
            caption: 'Email harus diisi !',
            classes: 'my-custom-font',
            actions: [{
              label: 'Dismiss',
              color: 'white',
              handler: function handler() {
                /* ... */
              }
            }]
          });
        } else if (error.response.data.message === 'Invalid Phone Number.') {
          _this7.$q.loading.hide();

          _this7.$q.notify({
            color: 'negative',
            message: 'Error',
            icon: 'close',
            classes: 'my-custom-font',
            position: 'top-right',
            caption: 'Nomor HP tidak valid !',
            actions: [{
              label: 'Dismiss',
              color: 'white',
              handler: function handler() {
                /* ... */
              }
            }]
          });
        } else if (error.response.data.message === '"phone" length must be at least 11 characters long') {
          _this7.$q.loading.hide();

          _this7.$q.notify({
            color: 'negative',
            message: 'Error',
            icon: 'close',
            position: 'top-right',
            caption: 'Nomor HP harus lebih dari 11 number',
            classes: 'my-custom-font',
            actions: [{
              label: 'Dismiss',
              color: 'white',
              handler: function handler() {
                /* ... */
              }
            }]
          });
        } else {
          _this7.$q.loading.hide();

          _this7.$q.notify({
            color: 'negative',
            message: 'Error',
            icon: 'close',
            position: 'top-right',
            classes: 'my-custom-font',
            caption: error.response.data.message,
            actions: [{
              label: 'Dismiss',
              color: 'white',
              handler: function handler() {
                /* ... */
              }
            }]
          });
        }
      });
    },
    update: function update() {
      var _this$selected$device,
          _this8 = this;

      this.$q.loading.show();
      axios({
        method: 'put',
        url: this.host + '/dashboard/user/' + this.selected.id,
        data: {
          id: this.selected.id,
          name: this.selected.name,
          email: this.selected.email,
          phone: this.selected.phone,
          role: this.selected.role,
          devices: (_this$selected$device = this.selected.devices) === null || _this$selected$device === void 0 ? void 0 : _this$selected$device.map(function (listdevice) {
            return listdevice.id;
          })
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (response) {
        _this8.getData();

        _this8.$q.notify({
          type: 'positive',
          color: 'positive',
          icon: 'check',
          message: 'Success',
          position: 'top-right',
          classes: 'my-custom-font',
          caption: '',
          actions: [{
            label: 'Dismiss',
            color: 'white',
            handler: function handler() {
              /* ... */
            }
          }]
        });

        _this8.dialogEdit = false;

        _this8.$q.loading.hide();
      }).catch(function (error) {
        if (error.response.data.message === '"phone" length must be at least 11 characters long') {
          _this8.$q.loading.hide();

          _this8.$q.notify({
            color: 'negative',
            message: 'Error',
            icon: 'close',
            position: 'top-right',
            caption: 'Nomor HP harus lebih dari 11 (angka)',
            classes: 'my-custom-font',
            actions: [{
              label: 'Dismiss',
              color: 'white',
              handler: function handler() {
                /* ... */
              }
            }]
          });
        } else {
          _this8.$q.loading.hide();

          _this8.$q.notify({
            color: 'negative',
            message: 'Error',
            icon: 'close',
            position: 'top-right',
            caption: error.response.data.message,
            classes: 'my-custom-font',
            actions: [{
              label: 'Dismiss',
              color: 'white',
              handler: function handler() {
                /* ... */
              }
            }]
          });
        }
      });
    },
    deleteUser: function deleteUser(row) {
      var _this9 = this;

      swal({
        title: 'Apakah anda yakin ?',
        text: 'Setelah dihapus, Anda tidak dapat memulihkan data ini !',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
        confirmButtonText: 'Hapus',
        denyButtonText: 'Batal',
        className: 'my-custom-font'
      }).then(function (willDelete) {
        if (willDelete) {
          _this9.destroy(row);
        }
      });
    },
    destroy: function destroy(row) {
      var _this10 = this;

      this.$q.loading.show();
      axios({
        method: 'delete',
        url: this.host + '/dashboard/user/' + row.id,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (response) {
        _this10.$q.loading.hide();

        _this10.dialogEdit = false;

        _this10.$q.notify({
          type: 'positive',
          color: 'positive',
          icon: 'check',
          message: 'Success',
          position: 'top-right',
          classes: 'my-custom-font',
          caption: '',
          actions: [{
            label: 'Dismiss',
            color: 'white',
            handler: function handler() {
              /* ... */
            }
          }]
        });

        _this10.getData();
      }).catch(function (error) {
        _this10.$q.loading.hide();

        _this10.$q.notify({
          color: 'negative',
          message: 'Error',
          icon: 'close',
          position: 'top-right',
          classes: 'my-custom-font',
          caption: error.response.data.message,
          actions: [{
            label: 'Dismiss',
            color: 'white',
            handler: function handler() {
              /* ... */
            }
          }]
        });
      });
    }
  }
};