import { render, staticRenderFns } from "./operator.vue?vue&type=template&id=22a15fc0&scoped=true&"
import script from "./operator.vue?vue&type=script&lang=js&"
export * from "./operator.vue?vue&type=script&lang=js&"
import style0 from "./operator.vue?vue&type=style&index=0&id=22a15fc0&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22a15fc0",
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QPageSticky from 'quasar/src/components/page-sticky/QPageSticky.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QInput,QIcon,QCardSection,QTable,QTr,QTd,QBtn,QPageSticky,QTooltip,QDialog,QCard,QSelect,QItem,QItemSection,QCardActions,QSpace,QAvatar});qInstall(component, 'directives', {ClosePopup});
