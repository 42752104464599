import _toConsumableArray from "/app/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray";
import "core-js/modules/es6.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import Vue from 'vue';
import axios from 'axios';
import date from 'quasar/src/utils/date.js';;
Vue.component('date-picker', DatePicker);
Vue.use(require('vue-moment'), {});
export default {
  components: {
    DatePicker: DatePicker
  },
  data: function data() {
    return {
      page: 1,
      minPages: 1,
      maxPages: 1,
      exportDialog: false,
      host: '',
      loadingOvo: false,
      loadingGopay: false,
      loadingLinkAja: false,
      loadingShopee: true,
      pagination: {
        rowsPerPage: 10
      },
      model: 'one',
      tab: 'ovo',
      secondModel: 'one',
      awal: '',
      akhir: '',
      hasil: '',
      range: {
        start: new Date(2020, 5, 1),
        end: new Date(2020, 5, 30)
      },
      filter: '',
      filter2: '',
      filter3: '',
      filter4: '',
      columns: [{
        name: 'sku',
        required: true,
        label: 'TRX ID',
        align: 'left',
        field: function field(row) {
          return row.trxId;
        },
        format: function format(val) {
          return "".concat(val);
        },
        sortable: true,
        headerClasses: 'bg-grey-2 ellipsis',
        classes: 'bg-grey-2 ellipsis'
      }, {
        name: 'provider',
        align: 'center',
        label: 'Provider',
        field: function field(row) {
          return row.provider;
        },
        sortable: true
      }, {
        name: 'date',
        align: 'center',
        label: 'Waktu Pembayaran',
        field: function field(row) {
          return row.datetime;
        },
        sortable: true
      }, {
        name: 'tid',
        align: 'center',
        label: 'TID',
        field: function field(row) {
          return row.tid;
        },
        sortable: true
      }, {
        name: 'mid',
        align: 'center',
        label: 'MID',
        field: function field(row) {
          return row.mid;
        },
        sortable: true
      }, {
        name: 'amount',
        align: 'center',
        label: 'Jumlah',
        field: function field(row) {
          return row.amount;
        },
        sortable: true
      }, {
        name: 'hp',
        align: 'center',
        label: 'Customer',
        field: function field(row) {
          return row.customer;
        },
        sortable: true
      }, {
        name: 'aproval',
        align: 'center',
        label: 'Approval Code',
        field: function field(row) {
          return row.approvalCode;
        },
        sortable: true
      }, {
        name: 'reff',
        align: 'center',
        label: 'Reff',
        field: function field(row) {
          return row.reffNo;
        },
        sortable: true
      }, {
        name: 'batch',
        align: 'center',
        label: 'Batch',
        field: function field(row) {
          return row.batchNo;
        },
        sortable: true
      }, {
        name: 'trace',
        align: 'center',
        label: 'Trace',
        field: function field(row) {
          return row.traceNo;
        },
        sortable: true
      }],
      dataOvo: [],
      dataLinkAja: [],
      dataGopay: [],
      columns4: [{
        name: 'ID',
        align: 'center',
        required: true,
        label: 'Payment ID',
        field: function field(row) {
          return row.id;
        },
        format: function format(val) {
          return "".concat(val);
        },
        sortable: true,
        headerStyle: 'font-weight: bold;',
        headerClasses: 'bg-light-green-2 ellipsis',
        classes: 'ellipsis'
      }, {
        name: 'TRX',
        required: true,
        label: 'TRX ID',
        align: 'center',
        field: function field(row) {
          return row.trxId;
        },
        headerStyle: 'font-weight: bold;',
        headerClasses: 'bg-light-green-2 ellipsis',
        format: function format(val) {
          return "".concat(val);
        },
        sortable: true
      }, {
        name: 'provider',
        align: 'center',
        label: 'Provider',
        field: function field(row) {
          return row.provider;
        },
        headerStyle: 'font-weight: bold;',
        headerClasses: 'bg-light-green-2 ellipsis',
        sortable: true
      }, {
        name: 'date',
        align: 'center',
        label: 'Waktu Pembayaran',
        field: function field(row) {
          return row.datetime;
        },
        headerStyle: 'font-weight: bold;',
        headerClasses: 'bg-light-green-2 ellipsis',
        sortable: true
      }, {
        name: 'tid',
        align: 'center',
        label: 'TID',
        field: function field(row) {
          return row.tid;
        },
        headerStyle: 'font-weight: bold;',
        headerClasses: 'bg-light-green-2 ellipsis',
        sortable: true
      }, {
        name: 'mid',
        align: 'center',
        label: 'MID',
        field: function field(row) {
          return row.mid;
        },
        headerStyle: 'font-weight: bold;',
        headerClasses: 'bg-light-green-2 ellipsis',
        sortable: true
      }, {
        name: 'amount',
        align: 'center',
        label: 'Jumlah',
        field: function field(row) {
          return row.amount;
        },
        headerStyle: 'font-weight: bold;',
        headerClasses: 'bg-light-green-2 ellipsis',
        sortable: true
      }, {
        name: 'status',
        align: 'center',
        label: 'Status',
        field: function field(row) {
          return row.status;
        },
        headerStyle: 'font-weight: bold;',
        headerClasses: 'bg-light-green-2 ellipsis',
        sortable: true
      }],
      dataShopee: [],
      dateStart: '',
      dateEnd: '',
      dataDevice: [],
      dataActiveTube: [],
      activeTube: '0',
      selectDevice: '',
      imei: '',
      dataProvider: [{
        label: 'All',
        value: ''
      }, {
        label: 'Dana',
        value: 'dana'
      }, {
        label: 'Ovo',
        value: 'ovo'
      }, {
        label: 'LinkAja',
        value: 'linkaja'
      }, {
        label: 'Shopee',
        value: 'shopee'
      }, {
        label: 'Gopay',
        value: 'gopay'
      }],
      selectProvider: '',
      provider: ''
    };
  },
  watch: {
    range: function range() {
      this.awal = date.formatDate(this.range.start, 'DD MMM YYYY');
      this.akhir = date.formatDate(this.range.end, 'DD MMM YYYY');
      this.hasil = this.awal + ' - ' + this.akhir;
      this.dateStart = date.formatDate(this.range.start, 'YYYY-MM-DD');
      this.dateEnd = date.formatDate(this.range.end, 'YYYY-MM-DD');
      this.getDataShopee();
      this.handleInputPage();
    },
    filter4: function filter4() {
      this.getDataShopee();
      this.handleInputPage();
    }
  },
  mounted: function mounted() {
    this.range.start = date.startOfDate(Date.now(), 'month');
    this.range.end = date.endOfDate(Date.now(), 'month');
    this.host = this.$store.state.global.host;
    this.awal = date.formatDate(this.range.start, 'DD MMM YYYY');
    this.akhir = date.formatDate(this.range.end, 'DD MMM YYYY');
    this.hasil = this.awal + ' - ' + this.akhir;
    this.dateStart = date.formatDate(this.range.start, 'YYYY-MM-DD');
    this.dateEnd = date.formatDate(this.range.end, 'YYYY-MM-DD');
    this.selectProvider = this.dataProvider[0];
    this.provider = this.selectProvider.value;
    this.getDevice();
    this.getData();
  },
  methods: {
    handleInputPage: function handleInputPage(page) {
      this.page = page;
      this.getData();
    },
    downloadExcel: function downloadExcel() {
      var _this = this;

      if (this.selectProvider.label === 'All') {
        this.$q.notify({
          color: 'red',
          message: 'Pilih Selain Provider All',
          position: 'top-right',
          caption: '',
          multiLine: true
        });
      } else {
        axios.get(this.host + '/dashboard/shopee-log/~excel?provider=' + this.selectProvider.value, {
          'responseType': 'blob',
          'headers': {
            'Authorization': 'Bearer ' + this.$cookie.get('token')
          },
          'Content-Disposition': '"attachment; filename=Riwayat Pengisian.xlsx"',
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }).then(function (res) {
          var url = window.URL.createObjectURL(new Blob([res.data]));
          var link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'QR Payments.xlsx');
          document.body.appendChild(link);
          link.click();

          _this.$q.notify({
            color: 'green',
            message: 'Download Success',
            position: 'top-right',
            caption: '',
            multiLine: true
          });
        }).catch(function (err) {
          _this.$q.notify({
            color: 'red',
            message: 'Error',
            position: 'top-right',
            caption: err,
            multiLine: true
          });

          if (err.response.status === 401) {
            _this.$cookie.delete('token');

            _this.$router.push('/login');
          }
        });
      }
    },
    peringatan: function peringatan() {
      this.$q.dialog({
        title: 'Pilih Selain ALL',
        message: 'Some message'
      }).onOk(function () {}).onCancel(function () {}).onDismiss(function () {});
    },
    exportButton: function exportButton() {
      this.exportDialog = true;
    },
    getDevice: function getDevice() {
      var _this2 = this;

      axios.get(this.host + '/dashboard/device/select', {
        'headers': {
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (res) {
        _this2.dataDevice = res.data;

        _this2.dataDevice.sort(function (a, b) {
          return a.name.localeCompare(b.name);
        });

        _this2.dataDevice = [{
          id: '',
          name: 'Semua Device',
          imei: ''
        }].concat(_toConsumableArray(_this2.dataDevice));

        if (_this2.dataDevice.length > 0) {
          if (!_this2.$store.state.global.filter.selectDevice) {
            var selectedDevice = _this2.dataDevice[0];
            _this2.$store.state.global.filter.selectDevice = selectedDevice;
            _this2.imei = selectedDevice.imei;
          } else {
            _this2.imei = _this2.$store.state.global.filter.selectDevice.imei;
          }
        }
      }).catch(function (err) {
        if (err.response.status === 401) {
          _this2.$cookie.delete('token');

          _this2.$router.push('/login');
        }
      });
    },
    getData1: function getData1(x) {
      this.imei = x.imei;
      this.getDataOvo();
    },
    getData2: function getData2(x) {
      this.imei = x.imei;
      this.getDataLinkAja();
    },
    getData3: function getData3(x) {
      this.imei = x.imei;
      this.getDataGoPay();
    },
    getData4: function getData4(x) {
      this.imei = x.imei;
      this.getDataShopee();
    },
    getData5: function getData5(x) {
      this.provider = x.value;
      this.getDataShopee();
    },
    getData: function getData() {
      this.getDataShopee();
    },
    getDataShopee: function getDataShopee() {
      var _this3 = this;

      this.loadingShopee = true;
      axios.get(this.host + '/dashboard/v1/qr-payment?limit=&page=' + this.page + '&from=' + this.dateStart + '&to=' + this.dateEnd + '&search=' + this.filter4 + '&deviceImei=' + this.imei + '&provider=' + this.provider, {
        'headers': {
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (res) {
        _this3.dataShopee = res.data.data;
        _this3.page = res.data.meta.page;
        _this3.maxPages = res.data.meta.totalPages;

        _this3.dataShopee.map(function (item) {
          item.datetime = _this3.$moment.utc(item.datetime).format('YYYY / MM / DD HH:mm:ss');
        });

        _this3.loadingShopee = false;
      }).catch(function (err) {
        _this3.loadingShopee = false;

        if (err.response.status === 401) {
          _this3.$cookie.delete('token');

          _this3.$router.push('/login');
        }
      });
    } // getDataOvo () {
    //   this.loadingOvo = true
    //   axios.get(this.host + '/ovo-log?from=' + this.dateStart + '&to=' + this.dateEnd + '&imei=' + this.imei, { 'headers': { 'Authorization': 'Bearer ' + this.$cookie.get('token') } }).then(res => {
    //   // axios.get(this.host + '/ovo-log', { 'headers': { 'Authorization': 'Bearer ' + this.$cookie.get('token') } }).then(res => {
    //     this.dataOvo = res.data
    //     this.dataOvo.map(item => {
    //       item.datetime = this.$moment.utc(item.datetime).format('DD-MMM-YYYY HH:mm:ss')
    //     })
    //     this.loading = false
    //   }).catch((err) => {
    //     this.loading = false
    //     if (err.response.status === 401) {
    //       this.$cookie.delete('token')
    //       this.$router.push('/login')
    //     }
    //   })
    // },
    // getDataLinkAja () {
    //   this.loadingOvo = true
    //   axios.get(this.host + '/linkaja-log?from=' + this.dateStart + '&to=' + this.dateEnd + '&imei=' + this.imei, { 'headers': { 'Authorization': 'Bearer ' + this.$cookie.get('token') } }).then(res => {
    //     this.dataLinkAja = res.data
    //     this.dataLinkAja.map(item => {
    //       // item.datetime = date.formatDate(item.datetime, 'DD-MMM-YYYY HH:MM:SS')
    //       item.datetime = this.$moment.utc(item.datetime).format('DD-MMM-YYYY HH:mm:ss')
    //     })
    //     this.loading = false
    //   }).catch((err) => {
    //     this.loading = false
    //     if (err.response.status === 401) {
    //       this.$cookie.delete('token')
    //       this.$router.push('/login')
    //     }
    //   })
    // },
    // getDataGoPay () {
    //   this.loadingOvo = true
    //   axios.get(this.host + '/gopay-log?from=' + this.dateStart + '&to=' + this.dateEnd + '&imei=' + this.imei, { 'headers': { 'Authorization': 'Bearer ' + this.$cookie.get('token') } }).then(res => {
    //     this.dataGopay = res.data
    //     this.dataGopay.map(item => {
    //       item.datetime = this.$moment.utc(item.datetime).format('DD-MMM-YYYY HH:mm:ss')
    //     })
    //     this.loadingOvo = false
    //   }).catch((err) => {
    //     this.loadingOvo = false
    //     if (err.response.status === 401) {
    //       this.$cookie.delete('token')
    //       this.$router.push('/login')
    //     }
    //   })
    // },

  }
};