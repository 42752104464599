import "core-js/modules/es6.math.sign.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import axios from 'axios';
export default {
  props: {
    dateStart: {
      type: String
    },
    dateEnd: {
      type: String
    }
  },
  data: function data() {
    return {
      host: '',
      totalTransaksi: null,
      totalPenjualan: null
    };
  },
  watch: {
    dateStart: function dateStart() {
      this.getTotal();
    },
    dateEnd: function dateEnd() {
      this.getTotal();
    }
  },
  created: function created() {
    this.host = this.$store.state.global.host;
  },
  mounted: function mounted() {
    this.getTotal();
  },
  methods: {
    getTotal: function getTotal() {
      var _this = this;

      this.seeLoading = true;
      axios.get(this.host + '/dashboard/analytic/total?from=' + this.dateStart + '&to=' + this.dateEnd, {
        'headers': {
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (res) {
        _this.totalTransaksi = _this.IDRCurrencyFormat(res.data.sales);
        _this.totalPenjualan = res.data.transactions;
        _this.seeLoading = false;
      });
    },
    IDRCurrencyFormat: function IDRCurrencyFormat(number) {
      if (number > 999999999) {
        return 'Rp.' + '\xa0' + Math.sign(number) * (Math.abs(number) / 1000000000).toFixed(1) + '\xa0\xa0' + 'M';
      } else {
        var idr = new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
          minimumFractionDigits: 0
        }).format(number);
        return idr;
      }
    }
  }
};