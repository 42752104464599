//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    totalTransaksi: {
      type: null
    },
    totalPenjualan: {
      type: null
    }
  },
  data: function data() {
    return {
      host: ''
    };
  },
  created: function created() {
    this.host = this.$store.state.global.host;
  }
};