import "core-js/modules/es6.math.sign.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Vue from 'vue';
import axios from 'axios';
Vue.component('date-picker');
export default {
  props: {
    filter: {
      type: String
    },
    imei: {
      type: String
    },
    dateStart: {
      type: String
    },
    dateEnd: {
      type: String
    }
  },
  data: function data() {
    return {
      exportDialog: false,
      host: '',
      date: '2019/02/01',
      awal: '',
      akhir: '',
      hasil: '',
      columns: [{
        name: 'id',
        required: true,
        label: 'Order ID',
        align: 'center',
        field: function field(row) {
          return row.id;
        },
        format: function format(val) {
          return "".concat(val);
        },
        headerStyle: 'font-weight: 600;',
        headerClasses: 'bg-light-green-2 ellipsis',
        sortable: true
      }, {
        name: 'deviceImei',
        required: true,
        label: 'Device Imei',
        align: 'center',
        field: function field(row) {
          return row.deviceImei;
        },
        format: function format(val) {
          return "".concat(val);
        },
        headerStyle: 'font-weight: 600; ',
        headerClasses: 'bg-light-green-2 ellipsis',
        sortable: true
      }, {
        name: 'orderType',
        align: 'center',
        label: 'Tipe Layanan',
        field: 'orderType',
        headerStyle: 'font-weight: 600;',
        headerClasses: 'bg-light-green-2 ellipsis',
        sortable: true
      }, {
        name: 'vehicle_type',
        align: 'center',
        label: 'Jenis Kendaraan',
        field: 'vehicleType',
        headerStyle: 'font-weight: 600;',
        headerClasses: 'bg-light-green-2 ellipsis',
        sortable: true
      }, {
        name: 'tireCount',
        align: 'center',
        label: 'Jumlah Ban',
        field: 'tireCount',
        headerStyle: 'font-weight: 600;',
        headerClasses: 'bg-light-green-2 ellipsis',
        sortable: true
      }, {
        name: 'total',
        align: 'center',
        label: 'Total',
        field: 'price',
        headerStyle: 'font-weight: 600;',
        headerClasses: 'bg-light-green-2 ellipsis',
        sortable: true
      }, {
        name: 'status',
        align: 'center',
        label: 'Status Pembayaran',
        field: 'status',
        headerStyle: 'font-weight: 600;',
        headerClasses: 'bg-light-green-2 ellipsis',
        sortable: true
      }, {
        name: 'created_at',
        align: 'left',
        label: 'Waktu',
        field: 'createdAt',
        headerStyle: 'font-weight: 600;',
        headerClasses: 'bg-light-green-2 ellipsis',
        sortable: true
      }],
      data: [],
      loading: false,
      page: 1,
      minPages: 1,
      maxPages: 1
    };
  },
  watch: {
    dateStart: function dateStart() {
      this.getData();
      this.handleInputPage();
    },
    dateEnd: function dateEnd() {
      this.getData();
      this.handleInputPage();
    },
    imei: function imei() {
      this.getData();
      this.handleInputPage();
    },
    filter: function filter() {
      this.getData();
      this.handleInputPage();
    }
  },
  created: function created() {
    this.host = this.$store.state.global.host;
  },
  mounted: function mounted() {
    var _this = this;

    this.loading = true;
    setTimeout(function () {
      _this.getData();
    }, 1000);
  },
  methods: {
    handleInputPage: function handleInputPage(page) {
      this.page = page;
      this.getData();
    },
    IDRCurrencyFormat: function IDRCurrencyFormat(number) {
      if (number > 999999999) {
        return 'Rp.' + '\xa0' + Math.sign(number) * (Math.abs(number) / 1000000000).toFixed(1) + '\xa0\xa0' + 'M';
      } else {
        var idr = new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
          minimumFractionDigits: 0
        }).format(number);
        return idr;
      }
    },
    getData: function getData() {
      var _this2 = this;

      this.loading = true;
      axios.get(this.host + '/dashboard/v1/order?limit=&page=' + this.page + '&from=' + this.dateStart + '&to=' + this.dateEnd + '&search=' + this.filter + '&deviceImei=' + this.imei, {
        'headers': {
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (res) {
        _this2.data = res.data.data;
        _this2.page = res.data.meta.page;
        _this2.maxPages = res.data.meta.totalPages;
        _this2.loading = false;
      }).catch(function (err) {
        _this2.loading = false;

        if (err.response.status === 401) {
          _this2.$cookie.delete('token');

          _this2.$router.push('/login');
        }
      });
    }
  }
};