import layoutPage from 'layouts/MyLayout.vue';
import DashboardPage from 'pages/dashboard.vue';
import MonitoringPage from 'pages/monitoring.vue';
import HistoryPage from 'pages/history.vue';
import DevicePage from 'pages/device.vue';
import SettlementPage from 'pages/settlement.vue';
import MaintenancePage from 'pages/maintenance.vue';
import AdminPage from 'pages/admin.vue';
import OperatorPage from 'pages/operator.vue';
var routes = [{
  path: '/login',
  component: function component() {
    return import('pages/Login.vue');
  }
}, {
  path: '/register',
  component: function component() {
    return import('pages/Register.vue');
  }
}, {
  path: '/',
  component: layoutPage,
  redirect: {
    name: 'Dashboard'
  },
  children: [{
    name: 'Statistik',
    path: 'dashboard',
    component: DashboardPage
  }, {
    name: 'Monitoring',
    path: 'monitoring',
    component: MonitoringPage
  }, {
    name: 'Riwayat',
    path: 'history',
    component: HistoryPage
  }, {
    name: 'Device',
    path: 'device',
    component: DevicePage
  }, {
    name: 'Settlement',
    path: 'settlement',
    component: SettlementPage
  }, {
    name: 'Perbaikan',
    path: 'maintenance',
    component: MaintenancePage
  }, {
    name: 'Pengguna',
    path: 'admin',
    component: AdminPage
  }, {
    name: 'Operator',
    path: 'operator',
    component: OperatorPage
  }]
}]; // Always leave this as last one

if (process.env.MODE !== 'ssr') {
  routes.push({
    path: '*',
    component: function component() {
      return import('pages/Error404.vue');
    }
  });
}

export default routes;