//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import Vue from 'vue';
import date from 'quasar/src/utils/date.js';;
import axios from 'axios';
Vue.component('date-picker', DatePicker);
export default {
  components: {
    DatePicker: DatePicker
  },
  data: function data() {
    return {
      loading1: false,
      pagination: {
        rowsPerPage: 10
      },
      host: '',
      model: 'one',
      secondModel: true,
      awal: '',
      akhir: '',
      hasil: '',
      range: {
        start: new Date(2020, 3, 1),
        end: new Date(2020, 4, 30)
      },
      filter: '',
      columns: [{
        name: 'id',
        required: true,
        label: 'ID',
        align: 'center',
        field: function field(row) {
          return row.id;
        },
        format: function format(val) {
          return "".concat(val);
        },
        sortable: true
      }, {
        name: 'paymentId',
        required: true,
        label: 'ID Payment',
        align: 'left',
        field: function field(row) {
          return row.paymentId;
        },
        format: function format(val) {
          return "".concat(val);
        },
        sortable: true,
        headerClasses: 'ellipsis',
        classes: ' ellipsis',
        style: 'max-width: 100px'
      }, {
        name: 'fileName',
        align: 'center',
        label: 'File Name',
        field: function field(row) {
          return row.fileName;
        },
        sortable: true,
        headerClasses: 'ellipsis',
        classes: 'ellipsis',
        style: 'max-width: 200px'
      }, // {
      //   name: 'createdAt',
      //   align: 'center',
      //   label: 'Date / Time',
      //   field: row => row.createdAt,
      //   sortable: true
      // },
      {
        name: 'sentDate2',
        align: 'center',
        label: 'Bussiness Date',
        field: function field(row) {
          return row.date;
        },
        sortable: true
      }, {
        name: 'lastSent2',
        align: 'center',
        label: 'Create Date',
        field: function field(row) {
          return row.datetime;
        },
        sortable: true
      }, {
        name: 'isSent',
        align: 'center',
        label: 'Status Send',
        field: function field(row) {
          return row.isSent;
        },
        sortable: true
      }, {
        name: 'createdBy',
        align: 'center',
        label: 'Created By',
        field: function field(row) {
          return row.createdBy;
        },
        sortable: true
      }, {
        name: 'sentDate',
        align: 'center',
        label: 'Send Date',
        field: function field(row) {
          return row.sentDate;
        },
        sortable: true
      }, {
        name: 'lastSent',
        align: 'center',
        label: 'Last Send',
        field: function field(row) {
          return row.lastSent;
        },
        sortable: true
      }, {
        name: 'totalRow',
        align: 'center',
        label: 'Total Row',
        field: function field(row) {
          return row.totalRow;
        },
        sortable: true
      }, {
        name: 'totalAmount',
        align: 'center',
        label: 'Total Amount',
        field: function field(row) {
          return row.totalAmount;
        },
        sortable: true
      }, {
        name: 'BeforeOrderQty',
        label: 'Mandiri (Row)',
        field: 'mandiriCount',
        sortable: true
      }, {
        name: 'BeforeOrderDays',
        label: 'Mandiri (Amount)',
        field: 'mandiriAmount',
        sortable: true
      }, {
        name: 'AfterOrderQty',
        label: 'BNI (Row)',
        field: 'bniCount',
        sortable: true
      }, {
        name: 'AfterOrderDays',
        label: 'BNI (Amount)',
        field: 'bniAmount',
        sortable: true
      }, {
        name: 'Price',
        label: 'BCA (Row)',
        field: 'bcaCount',
        sortable: true
      }, {
        name: 'Discount',
        label: 'BCA (Amount)',
        field: 'bcaAmount',
        sortable: true
      }, {
        name: 'Price2',
        label: 'BRI (Row)',
        field: 'briCount',
        sortable: true
      }, {
        name: 'Discount2',
        label: 'BRI (Amount)',
        field: 'briAmount',
        sortable: true
      }],
      data: [],
      dateStart: '',
      dateEnd: '',
      loading: false,
      loading2: false,
      generateData: {
        email: 'admin@mynitrovend.id',
        password: 'password'
      },
      selected: {},
      dialogEdit: false,
      getDataInterval: null
    };
  },
  watch: {
    secondModel: function secondModel() {
      this.getData();
    },
    range: function range() {
      this.awal = date.formatDate(this.range.start, 'DD MMM YYYY');
      this.akhir = date.formatDate(this.range.end, 'DD MMM YYYY');
      this.hasil = this.awal + ' - ' + this.akhir;
      this.dateStart = date.formatDate(this.range.start, 'YYYY-MM-DD');
      this.dateEnd = date.formatDate(this.range.end, 'YYYY-MM-DD');
      this.getData();
    }
  },
  mounted: function mounted() {
    var _this = this;

    this.range.start = date.startOfDate(Date.now(), 'month');
    this.range.end = date.endOfDate(Date.now(), 'month');
    this.host = this.$store.state.global.host;
    this.awal = date.formatDate(this.range.start, 'DD MMM YYYY');
    this.akhir = date.formatDate(this.range.end, 'DD MMM YYYY');
    this.hasil = this.awal + ' - ' + this.akhir;
    this.dateStart = date.formatDate(this.range.start, 'YYYY-MM-DD');
    this.dateEnd = date.formatDate(this.range.end, 'YYYY-MM-DD');
    this.getData();
    this.getDataInterval = setInterval(function () {
      _this.getData();
    }, 180000);
  },
  destroyed: function destroyed() {
    if (this.getDataInterval) {
      clearInterval(this.getDataInterval);
    }
  },
  methods: {
    submit: function submit() {
      var _this2 = this;

      this.loading1 = true;
      axios({
        method: 'post',
        url: this.host + '/settlement/submit/' + this.selected.id,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (response) {
        _this2.loading1 = false;

        if (response.data.status === true) {
          _this2.$q.notify({
            color: 'green',
            message: 'Success',
            position: 'top-right',
            caption: '',
            multiLine: true
          });

          _this2.getData();

          _this2.dialogEdit = false;
        } else {
          _this2.$q.notify({
            color: 'red',
            message: 'Status : ' + response.data.status,
            position: 'top-right',
            caption: '',
            multiLine: true
          });

          _this2.getData();
        }
      }).catch(function (error) {
        _this2.loading1 = false;

        _this2.$q.notify({
          color: 'red',
          message: 'Error code ' + error.response.status,
          position: 'top-right',
          caption: '',
          multiLine: true
        });
      });
    },
    onRowClick: function onRowClick(evt, row) {
      this.selected = row;
      this.dialogEdit = true;
    },
    generate: function generate() {
      var _this3 = this;

      this.loading2 = true;
      axios({
        method: 'post',
        url: this.host + '/settlement',
        data: this.generateData,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (response) {
        _this3.loading2 = false;

        if (response.data.status === true) {
          _this3.$q.notify({
            color: 'green',
            message: 'Settlement sedang digenerate oleh server.',
            position: 'top-right',
            caption: '',
            multiLine: true
          });
        } else {
          _this3.$q.notify({
            color: 'red',
            message: 'Status : ' + response.data.status,
            position: 'top-right',
            caption: '',
            multiLine: true
          });
        }
      }).catch(function (error) {
        _this3.loading2 = false;

        _this3.$q.notify({
          color: 'red',
          message: 'Error code ' + error.response.status,
          position: 'top-right',
          caption: '',
          multiLine: true
        });
      });
    },
    getData: function getData() {
      var _this4 = this;

      this.loading = true;
      axios.get(this.host + '/settlement?from=' + this.dateStart + '&to=' + this.dateEnd + '&isSent=' + this.secondModel, {
        'headers': {
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (res) {
        _this4.data = res.data;

        for (var i = 0; i < _this4.data.length; i++) {
          _this4.$set(_this4.data[i], 'totalRow', _this4.data[i].mandiriAmount + _this4.data[i].bniAmount + _this4.data[i].bcaAmount + _this4.data[i].briAmount);

          _this4.$set(_this4.data[i], 'totalAmount', _this4.data[i].mandiriCount + _this4.data[i].bniCount + _this4.data[i].bcaCount + _this4.data[i].briCount);
        }

        _this4.data.map(function (item) {
          // item.createdAt = date.formatDate(item.createdAt, 'DD-MMM-YYYY HH:MM:SS')
          // item.date = this.$moment.utc(item.date).format('DD-MMM-YYYY')
          // item.createdAt = this.$moment.utc(item.createdAt).format('-MMM-YYYY HH:mm:ss')
          if (item.sentDate === null) {
            item.sentDate = '-';
          }

          if (item.lastSent === null) {
            item.lastSent = '-';
          }
        });

        _this4.loading = false;
      }).catch(function (err) {
        _this4.loading = false;

        if (err.response.status === 401) {
          _this4.$cookie.delete('token');

          _this4.$router.push('/login');
        }
      });
    }
  }
};