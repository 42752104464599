import { render, staticRenderFns } from "./qrpayment.vue?vue&type=template&id=0526daa3&scoped=true&"
import script from "./qrpayment.vue?vue&type=script&lang=js&"
export * from "./qrpayment.vue?vue&type=script&lang=js&"
import style0 from "./qrpayment.vue?vue&type=style&index=0&id=0526daa3&rel=stylesheet%2Fscss&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0526daa3",
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QPagination from 'quasar/src/components/pagination/QPagination.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QTable,QInput,QIcon,QBtn,QSelect,QTr,QTd,QPagination,QDialog,QCard,QCardSection,QSpace,QSeparator,QCardActions});qInstall(component, 'directives', {ClosePopup});
