import Vue from 'vue';
import Vuex from 'vuex';
import global from "./global";
Vue.use(Vuex);
/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

export default function ()
/* { ssrContext } */
{
  var Store = new Vuex.Store({
    modules: {
      global: global
    },
    // enable strict mode (adds overhead!)
    // for dev mode only
    strict: process.env.DEV
  });
  return Store;
}