import "core-js/modules/es6.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import axios from 'axios';
import swal from 'sweetalert';
export default {
  name: 'Device',
  data: function data() {
    return {
      valueAll: false,
      device_id: '',
      rawSecretKey: '',
      modelAddDevices: {
        priceSet: ''
      },
      modelPriceSet: {},
      dataPriced: {},
      dataPriced2: {},
      clickedLocation: '',
      dialogAdd: false,
      dialogEdit: false,
      dialogResp: false,
      dialogResp2: false,
      dialogConfirmALLEPON: false,
      dialogConfirmALLEPOFF: false,
      dialogConfirmROWEPON: false,
      dialogConfirmROWEPOFF: false,
      host: '',
      filter: '',
      pagination: {
        rowsPerPage: 10
      },
      loading: true,
      columns: [{
        name: 'id',
        required: true,
        label: 'Device ID',
        align: 'center',
        field: function field(row) {
          return row.id;
        },
        format: function format(val) {
          return "".concat(val);
        },
        headerStyle: 'font-weight: bold;',
        headerClasses: 'bg-light-green-2 ellipsis',
        sortable: true
      }, {
        name: 'imei',
        required: true,
        label: 'IMEI',
        align: 'center',
        field: function field(row) {
          return row.imei;
        },
        format: function format(val) {
          return "".concat(val);
        },
        headerStyle: 'font-weight: bold;',
        headerClasses: 'bg-light-green-2 ellipsis',
        sortable: true
      }, {
        name: 'name',
        align: 'left',
        label: 'Nama Device',
        field: 'name',
        headerClasses: 'bg-light-green-2 ellipsis',
        headerStyle: 'font-weight: bold;',
        sortable: true
      }, {
        name: 'address',
        align: 'left',
        label: 'Alamat',
        field: 'address',
        sortable: true,
        classes: 'ellipsis',
        headerStyle: 'font-weight: bold;',
        headerClasses: 'bg-light-green-2 ellipsis',
        style: 'max-width: 100px'
      }, {
        name: 'qrPaymentTid',
        align: 'center',
        label: 'TID',
        field: 'qrPaymentTid',
        headerStyle: 'font-weight: bold;',
        headerClasses: 'bg-light-green-2 ellipsis',
        sortable: true
      }, {
        name: 'created_at',
        align: 'left',
        label: 'Waktu Daftar',
        field: 'createdAt',
        headerStyle: 'font-weight: bold;',
        headerClasses: 'bg-light-green-2 ellipsis',
        sortable: true
      }, {
        name: 'Action',
        label: 'Aksi',
        color: '#F5FAF0',
        align: 'center',
        headerStyle: 'font-weight: bold;',
        headerClasses: 'bg-light-green-2 ellipsis'
      }],
      data2: [],
      data: {},
      selected: [],
      location: null,
      latitude: '',
      longitude: '',
      gettingLocation: false,
      errorStr: null,
      getDataInterval: null,
      center: {
        lat: 0,
        lng: 0
      },
      markers: [{
        position: {
          lat: 0,
          lng: 0
        }
      }]
    };
  },
  mounted: function mounted() {
    this.host = this.$store.state.global.host;
    this.getData();
    this.EPALL();
  },
  destroyed: function destroyed() {
    if (this.getDataInterval) {
      clearInterval(this.getDataInterval);
    }
  },
  methods: {
    EPALL: function EPALL() {
      var _this = this;

      axios.get(this.host + '/dashboard/device/emergency-payment', {
        'headers': {
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (res) {
        _this.valueAll = res.data.status;
      }).catch(function (error) {
        console.log(error);
      });
    },
    EPALLActiveBatal: function EPALLActiveBatal() {
      this.dialogConfirmALLEPON = false;
      this.EPALL();
    },
    EPROWActiveBatal: function EPROWActiveBatal() {
      this.dialogConfirmROWEPON = false;
      this.getData();
    },
    EPALLActive: function EPALLActive() {
      var _this2 = this;

      this.dialogConfirmALLEPON = false;
      axios({
        method: 'patch',
        url: this.host + '/dashboard/device/emergency-payment',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (response) {
        _this2.getData();

        _this2.EPALL();

        _this2.$q.notify({
          type: 'positive',
          color: 'positive',
          icon: 'check',
          message: 'Emergency Payment Active (ON) for All Device',
          position: 'top-right',
          classes: 'my-custom-font',
          caption: '',
          actions: [{
            label: 'Dismiss',
            color: 'white',
            handler: function handler() {
              /* ... */
            }
          }]
        });
      }).catch(function (error) {
        _this2.$q.notify({
          color: 'negative',
          message: 'Gagal Mengaktifkan Emergency Payment',
          icon: 'close',
          position: 'top-right',
          actions: [{
            label: 'Dismiss',
            color: 'white',
            handler: function handler() {
              /* ... */
            }
          }]
        });

        console.log(error);
      });
    },
    EPROWActive: function EPROWActive() {
      var _this3 = this;

      this.dialogConfirmROWEPON = false;
      axios({
        method: 'patch',
        url: this.host + '/dashboard/device/emergency-payment/' + this.device_id,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (response) {
        _this3.$q.notify({
          type: 'positive',
          color: 'positive',
          icon: 'check',
          message: 'Emergency Payment Active (ON)',
          position: 'top-right',
          classes: 'my-custom-font',
          caption: '',
          actions: [{
            label: 'Dismiss',
            color: 'white',
            handler: function handler() {
              /* ... */
            }
          }]
        });
      }).catch(function (error) {
        _this3.$q.notify({
          color: 'negative',
          message: 'Gagal Mengaktifkan Emergency Payment',
          icon: 'close',
          position: 'top-right',
          actions: [{
            label: 'Dismiss',
            color: 'white',
            handler: function handler() {
              /* ... */
            }
          }]
        });

        console.log(error);
      });
    },
    EPALLNonActiveBatal: function EPALLNonActiveBatal() {
      this.dialogConfirmALLEPOFF = false;
      this.EPALL();
    },
    EPROWNonActiveBatal: function EPROWNonActiveBatal() {
      this.dialogConfirmROWEPOFF = false;
      this.getData();
    },
    EPALLNonActive: function EPALLNonActive() {
      var _this4 = this;

      this.dialogConfirmALLEPOFF = false;
      this.dialogConfirmALLEPON = false;
      axios({
        method: 'patch',
        url: this.host + '/dashboard/device/emergency-payment',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (response) {
        _this4.getData();

        _this4.EPALL();

        _this4.$q.notify({
          type: 'positive',
          color: 'positive',
          icon: 'check',
          message: 'Emergency Payment Not-Active (OFF) for All Device',
          position: 'top-right',
          classes: 'my-custom-font',
          caption: '',
          actions: [{
            label: 'Dismiss',
            color: 'white',
            handler: function handler() {
              /* ... */
            }
          }]
        });
      }).catch(function (error) {
        _this4.$q.notify({
          color: 'negative',
          message: 'Gagal Menonaktifkan Emergency Payment for All Device',
          icon: 'close',
          position: 'top-right',
          actions: [{
            label: 'Dismiss',
            color: 'white',
            handler: function handler() {
              /* ... */
            }
          }]
        });

        console.log(error);
      });
    },
    EPROWNonActive: function EPROWNonActive() {
      var _this5 = this;

      this.dialogConfirmROWEPOFF = false;
      this.dialogConfirmROWEPON = false;
      axios({
        method: 'patch',
        url: this.host + '/dashboard/device/emergency-payment/' + this.device_id,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (response) {
        _this5.getData();

        _this5.$q.notify({
          type: 'positive',
          color: 'positive',
          icon: 'check',
          message: 'Emergency Payment Not-Active (OFF)',
          position: 'top-right',
          classes: 'my-custom-font',
          caption: '',
          actions: [{
            label: 'Dismiss',
            color: 'white',
            handler: function handler() {
              /* ... */
            }
          }]
        });
      }).catch(function (error) {
        _this5.$q.notify({
          color: 'negative',
          message: 'Gagal Menonaktifkan Emergency Payment',
          icon: 'close',
          position: 'top-right',
          actions: [{
            label: 'Dismiss',
            color: 'white',
            handler: function handler() {
              /* ... */
            }
          }]
        });

        console.log(error);
      });
    },
    tutupall: function tutupall() {
      this.dialogResp = false;
      this.dialogResp2 = false;
    },
    doCopy: function doCopy() {
      var _this6 = this;

      if (this.rawSecretKey.length > 0) {
        this.$copyText(this.rawSecretKey).then(function (response) {
          _this6.$q.notify({
            icon: 'announcement',
            message: 'Tersalin',
            progress: true,
            color: 'primary',
            position: 'center',
            textColor: 'white',
            timeout: 1000,
            classes: 'glossy my-custom-font'
          });
        });
      } else {
        this.$q.notify({
          icon: 'announcement',
          message: 'Tidak Tersalin',
          progress: true,
          color: 'negative',
          timeout: 1000,
          position: 'center',
          textColor: 'white',
          classes: 'glossy'
        });
      }
    },
    coba: function coba(x) {
      var _this7 = this;

      axios.get(this.host + '/dashboard/device/' + x.id, {
        'headers': {
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (res) {
        if (res.data.priceSet !== null) {
          _this7.dataPriced = res.data.priceSet;
        }

        _this7.selected = res.data;
      }).catch(function (err) {
        if (err.response.status === 401) {
          _this7.$cookie.delete('token');

          _this7.$router.push('/login');
        }
      });
      this.dialogEdit = true;
    },
    tutup: function tutup() {
      this.dialogAdd = false;
      this.dialogEdit = false;
      this.defaultValueAdd();
    },
    defaultValueAdd: function defaultValueAdd() {
      this.modelPriceSet = {};
    },
    clicked: function clicked(e) {
      this.markers[0].position.lat = e.latLng.lat();
      this.markers[0].position.lng = e.latLng.lng();
      this.data.latitude = e.latLng.lat();
      this.data.longitude = e.latLng.lng();
      this.selected.latitude = e.latLng.lat();
      this.selected.longitude = e.latLng.lng();
    },
    getLocation: function getLocation() {
      var _this8 = this;

      if (!('geolocation' in navigator)) {
        this.errorStr = 'Geolocation is not available.';
        return;
      }

      this.gettingLocation = true;
      navigator.geolocation.getCurrentPosition(function (pos) {
        _this8.gettingLocation = false;
        _this8.location = pos;
        _this8.markers[0].position.lat = _this8.location.coords.latitude;
        _this8.markers[0].position.lng = _this8.location.coords.longitude;
        _this8.data.latitude = _this8.location.coords.latitude;
        _this8.data.longitude = _this8.location.coords.longitude;
        _this8.selected.latitude = _this8.location.coords.latitude;
        _this8.selected.longitude = _this8.location.coords.longitude;
      }, function (err) {
        _this8.gettingLocation = false;
        _this8.errorStr = err.message;
      });
    },
    onRowClick: function onRowClick(evt, row) {
      var _this9 = this;

      this.selected = row;
      this.center.lat = this.selected.latitude;
      this.center.lng = this.selected.longitude;
      this.markers[0].position.lat = this.selected.latitude;
      this.markers[0].position.lng = this.selected.longitude;
      axios.get(this.host + '/device/' + this.selected.imei + '/price-set', {
        'headers': {
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (res) {
        _this9.dataPriced = res.data.priceSet;
        _this9.dataPriced2 = res.data;
      }).catch(function (err) {
        if (err.response.status === 401) {
          _this9.$cookie.delete('token');

          _this9.$router.push('/login');
        }
      });
      this.dialogEdit = true;
    },
    getPriceSet: function getPriceSet() {
      var _this10 = this;

      this.loading = true;
      axios.get(this.host + '/device', {
        'headers': {
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (res) {
        _this10.data2 = res.data;
      }).catch(function (err) {
        if (err.response.status === 401) {
          _this10.$cookie.delete('token');

          _this10.$router.push('/login');
        }
      });
    },
    getData: function getData() {
      var _this11 = this;

      this.loading = true;
      axios.get(this.host + '/dashboard/device/', {
        'headers': {
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (res) {
        _this11.data2 = res.data;
        _this11.loading = false;
      }).catch(function (err) {
        _this11.loading = false;

        if (err.response.status === 401) {
          _this11.$cookie.delete('token');

          _this11.$router.push('/login');
        }
      });
    },
    save: function save() {
      var _this12 = this;

      this.$q.loading.show();
      this.modelAddDevices.priceSet = this.modelPriceSet;
      axios({
        method: 'post',
        url: this.host + '/dashboard/device',
        data: this.modelAddDevices,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (response) {
        _this12.$q.loading.hide();

        _this12.rawSecretKey = response.data.rawSecretKey;
        _this12.dialogAdd = false;
        _this12.dialogResp = true;

        _this12.defaultValueAdd();

        _this12.$q.notify({
          type: 'positive',
          color: 'positive',
          icon: 'check',
          message: 'Success',
          position: 'top-right',
          classes: 'my-custom-font',
          caption: '',
          actions: [{
            label: 'Dismiss',
            color: 'white',
            handler: function handler() {
              /* ... */
            }
          }]
        });

        _this12.getData();
      }).catch(function (error) {
        _this12.$q.loading.hide();

        _this12.$q.notify({
          color: 'negative',
          message: 'Error',
          icon: 'close',
          position: 'top-right',
          caption: error.response.data.message,
          actions: [{
            label: 'Dismiss',
            color: 'white',
            handler: function handler() {
              /* ... */
            }
          }]
        });
      });
    },
    update: function update() {
      var _this13 = this;

      this.$q.loading.show();
      axios({
        method: 'put',
        url: this.host + '/dashboard/device/' + this.selected.id,
        data: {
          id: this.selected.id,
          name: this.selected.name,
          imei: this.selected.imei,
          address: this.selected.address,
          qrPaymentTid: this.selected.qrPaymentTid,
          latitude: '0',
          longitude: '0',
          priceSet: {
            carNew: this.dataPriced.carNew,
            carRefill: this.dataPriced.carRefill,
            carPatch: this.dataPriced.carPatch,
            bikeNew: this.dataPriced.bikeNew,
            bikeRefill: this.dataPriced.bikeRefill,
            bikePatch: this.dataPriced.bikePatch
          }
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (response) {
        _this13.$q.loading.hide();

        _this13.dialogEdit = false;

        _this13.$q.notify({
          type: 'positive',
          color: 'positive',
          icon: 'check',
          message: 'Success',
          position: 'top-right',
          classes: 'my-custom-font',
          caption: '',
          actions: [{
            label: 'Dismiss',
            color: 'white',
            handler: function handler() {
              /* ... */
            }
          }]
        });

        _this13.getData();
      }).catch(function (error) {
        _this13.$q.loading.hide();

        _this13.$q.notify({
          color: 'negative',
          message: 'Error',
          icon: 'close',
          position: 'top-right',
          caption: error.response.data.message,
          actions: [{
            label: 'Dismiss',
            color: 'white',
            handler: function handler() {
              /* ... */
            }
          }]
        });
      });
    },
    deleteDevice: function deleteDevice(row) {
      var _this14 = this;

      swal({
        title: 'Apakah anda yakin ?',
        text: 'Setelah dihapus, Anda tidak dapat memulihkan data ini !',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
        confirmButtonText: 'Hapus',
        denyButtonText: 'Batal',
        className: 'my-custom-font'
      }).then(function (willDelete) {
        if (willDelete) {
          _this14.destroy(row);
        }
      });
    },
    checkEPALL: function checkEPALL() {
      if (!this.valueAll) {
        this.dialogConfirmALLEPOFF = true;
      } else {
        this.dialogConfirmALLEPON = true;
      }
    },
    checkEPROW: function checkEPROW(row) {
      this.device_id = row.id;

      if (!row.emergencyPaymentStatus) {
        this.dialogConfirmROWEPOFF = true;
      } else {
        this.dialogConfirmROWEPON = true;
      }
    },
    destroy: function destroy(row) {
      var _this15 = this;

      this.$q.loading.show();
      axios({
        method: 'delete',
        url: this.host + '/dashboard/device/' + row.id,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (response) {
        _this15.$q.loading.hide();

        _this15.dialogEdit = false;

        _this15.$q.notify({
          type: 'positive',
          color: 'positive',
          icon: 'check',
          message: 'Success',
          position: 'top-right',
          classes: 'my-custom-font',
          caption: '',
          actions: [{
            label: 'Dismiss',
            color: 'white',
            handler: function handler() {
              /* ... */
            }
          }]
        });

        _this15.getData();
      }).catch(function (error) {
        _this15.$q.loading.hide();

        _this15.$q.notify({
          color: 'negative',
          message: 'Error',
          icon: 'close',
          position: 'top-right',
          caption: error.response.data.message,
          actions: [{
            label: 'Dismiss',
            color: 'white',
            handler: function handler() {
              /* ... */
            }
          }]
        });
      });
    }
  }
};