import Driver from 'driver.js';
var driver = new Driver();
export var dashboardTour = function dashboardTour() {
  driver.defineSteps([{
    element: '#first-element-introduction',
    popover: {
      className: 'first-step-popover-class',
      title: 'Title on Popover',
      description: 'Body of the popover',
      position: 'bottom'
    }
  }, {
    element: '#second-element-introduction',
    popover: {
      title: 'Title on Popover',
      description: 'Body of the popover',
      position: 'top'
    }
  }, {
    element: '#third-element-introduction',
    popover: {
      title: 'Title on Popover',
      description: 'Body of the popover',
      position: 'right'
    }
  }]);
  setTimeout(function () {
    driver.start();
  }, 1000); // setTimeout(() => {
  //   driver.highlight({
  //     element: '#some-element',
  //     popover: {
  //       title: 'Title for the Popover',
  //       description: 'Description for it'
  //     }
  //   })
  // }, 1000)
};
export var userTour = function userTour() {
  driver.defineSteps([{
    element: '#user-0',
    popover: {
      className: 'first-step-popover-class',
      title: 'Yuk, gunakan tools search',
      description: ' Pencarianmu akan terasa lebih mudah',
      position: 'bottom'
    }
  }, {
    element: '#user-1',
    popover: {
      title: 'Data User',
      description: 'Data ini privasi ya. Yuk manage dengan benar',
      position: 'top'
    }
  }, {
    element: '#user-2',
    popover: {
      title: 'Admin Role',
      description: 'Admin sekarang bisa loh buat manage beberapa device. Yuk, mulai memanagenya',
      position: 'left'
    }
  }, {
    element: '#user-3',
    popover: {
      title: 'Superadmin Role',
      description: 'Superadmin banyak kelebihannya ya, diantaranya bisa nemanbah user. Yuk explore lebih terkait fitur yang lainnya.',
      position: 'left'
    }
  }, {
    element: '#user-4',
    popover: {
      title: 'Action Edit',
      description: 'Data user ada perubahan ? <br> Yuk, edit dulu dengan klik tombol ini.',
      position: 'left'
    }
  }, {
    element: '#user-5',
    popover: {
      title: 'Action Delete',
      description: 'User sudah tidak aktif lagi ? <br> Gapapa kok kalau mau dihapus',
      position: 'left'
    }
  }]);
  setTimeout(function () {
    driver.start();
  }, 1000);
};