import "core-js/modules/es6.math.sign.js";
import "core-js/modules/es6.array.find-index.js";
import "core-js/modules/es6.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import axios from 'axios';
export default {
  props: {
    dateStart: {
      type: String
    },
    dateEnd: {
      type: String
    }
  },
  data: function data() {
    return {
      host: '',
      seeLoading: false,
      dataPeringkat: [],
      paginationR: {},
      columns: [{
        name: 'index',
        required: true,
        label: 'NO',
        align: 'left',
        field: function field(row) {
          return row.id;
        },
        format: function format(val) {
          return "".concat(val);
        },
        headerStyle: 'font-weight: 600; color: #616161',
        headerClasses: 'bg-light-green-2 ellipsis',
        sortable: true
      }, {
        name: 'device',
        required: true,
        label: 'Nama Terminal',
        align: 'left',
        field: function field(row) {
          return row.name;
        },
        format: function format(val) {
          return "".concat(val);
        },
        headerStyle: 'font-weight: 600; color: #616161',
        headerClasses: 'bg-light-green-2 ellipsis',
        sortable: true
      }, {
        name: 'sum',
        align: 'left',
        label: 'Total Penjualan',
        field: function field(row) {
          return row.sum;
        },
        format: function format(val) {
          return "".concat(val);
        },
        headerClasses: 'bg-light-green-2 ellipsis',
        headerStyle: 'font-weight: 600; color: #616161',
        sortable: true
      }, {
        name: 'contribute',
        align: 'center',
        label: 'Kontribusi(%)',
        field: function field(row) {
          return row.contribute;
        },
        format: function format(val) {
          return "".concat(val);
        },
        headerClasses: 'bg-light-green-2 ellipsis',
        headerStyle: 'font-weight: 600; color: #616161',
        sortable: true
      }]
    };
  },
  watch: {
    dateStart: function dateStart() {
      this.getTopTransaction();
    },
    dateEnd: function dateEnd() {
      this.getTopTransaction();
    }
  },
  mounted: function mounted() {
    this.getTopTransaction();
  },
  created: function created() {
    this.host = this.$store.state.global.host;
  },
  methods: {
    getTopTransaction: function getTopTransaction() {
      var _this = this;

      this.seeLoading = true;
      axios.get(this.host + '/dashboard/analytic/top-transaction?from=' + this.dateStart + '&to=' + this.dateEnd, {
        'headers': {
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (res) {
        _this.dataPeringkat = res.data;
        _this.seeLoading = false;
      });
    },
    getIndex: function getIndex(id) {
      return this.dataPeringkat.findIndex(function (data) {
        return data.id === id;
      });
    },
    IDRCurrencyFormat: function IDRCurrencyFormat(number) {
      if (number > 999999999) {
        return 'Rp.' + '\xa0' + Math.sign(number) * (Math.abs(number) / 1000000000).toFixed(1) + '\xa0\xa0' + 'M';
      } else {
        var idr = new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
          minimumFractionDigits: 0
        }).format(number);
        return idr;
      }
    }
  }
};