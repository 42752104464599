export default function () {
  return {
    host: process.env.API_URL,
    role: '',
    filter: {
      selectDevice: null
    },
    paginationRanking: {
      page: 1,
      rowsPerPage: 10
    }
  };
}