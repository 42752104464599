import { render, staticRenderFns } from "./emergency.vue?vue&type=template&id=d3c66136&scoped=true&"
import script from "./emergency.vue?vue&type=script&lang=js&"
export * from "./emergency.vue?vue&type=script&lang=js&"
import style0 from "./emergency.vue?vue&type=style&index=0&id=d3c66136&rel=stylesheet%2Fscss&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d3c66136",
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QPagination from 'quasar/src/components/pagination/QPagination.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QTable,QInnerLoading,QTr,QTd,QPagination});
