import { render, staticRenderFns } from "./MyLayout.vue?vue&type=template&id=64f737c0&scoped=true&class=bg-grey-1&"
import script from "./MyLayout.vue?vue&type=script&lang=js&"
export * from "./MyLayout.vue?vue&type=script&lang=js&"
import style0 from "./MyLayout.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./MyLayout.vue?vue&type=style&index=1&id=64f737c0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64f737c0",
  null
  
)

export default component.exports
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QLayout,QHeader,QToolbar,QBtn,QToolbarTitle,QTooltip,QDrawer,QScrollArea,QList,QItem,QItemSection,QIcon,QPageContainer});qInstall(component, 'directives', {Ripple});
