//
//
//
//
//
//
import Vue from 'vue';
import VueCookie from 'vue-cookie';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import VueTour from 'vue-tour';

require('vue-tour/dist/vue-tour.css');

import 'driver.js/dist/driver.min.css';
Vue.use(VueCookie, VueTour);
export default {
  name: 'App',
  created: function created() {
    if (this.$cookie.get('token') == null) {
      this.$router.push('/login');
    }
  }
};
Sentry.init({
  Vue: Vue,
  dsn: process.env.SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  logErrors: true,
  attachProps: true
});