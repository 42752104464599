import _toConsumableArray from "/app/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.math.sign.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import axios from 'axios';
import date from 'quasar/src/utils/date.js';;
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import Vue from 'vue';
import RiwayatTransaksi from "../components/riwayatTransaksi.vue";
import Payment from "../components/payment.vue";
import Emergency from "../components/emergency.vue";
import TotalOrder from "../components/totalOrder.vue";
Vue.component('date-picker');
export default {
  name: 'History',
  components: {
    DatePicker: DatePicker,
    RiwayatTransaksi: RiwayatTransaksi,
    Payment: Payment,
    Emergency: Emergency,
    TotalOrder: TotalOrder
  },
  data: function data() {
    return {
      tab: 'pengisian',
      exportDialog: false,
      host: '',
      filter: '',
      pagination: {
        rowsPerPage: 10
      },
      loading: false,
      date: '2019/02/01',
      awal: '',
      akhir: '',
      hasil: '',
      range: {
        start: new Date(2020, 5, 1),
        end: new Date(2020, 5, 30)
      },
      dateStart: '',
      dateEnd: '',
      dataDevice: [],
      selectDevice: '',
      imei: '',
      dataExcel: {},
      getDataInterval: null,
      paymentType: 'qr',
      totalTransactionsOrder: null,
      totalSalesOrder: null,
      totalTransactionsPayment: null,
      totalSalesPayment: null,
      totalTransactionsEP: null,
      totalSalesEP: null
    };
  },
  watch: {
    range: function range() {
      this.awal = date.formatDate(this.range.start, 'DD MMM YYYY');
      this.akhir = date.formatDate(this.range.end, 'DD MMM YYYY');
      this.hasil = this.awal + ' - ' + this.akhir;
      this.dateStart = date.formatDate(this.range.start, 'YYYY-MM-DD');
      this.dateEnd = date.formatDate(this.range.end, 'YYYY-MM-DD');
      this.TotalOrder();
      this.TotalPayment();
      this.TotalEP();
    }
  },
  mounted: function mounted() {
    this.range.start = date.startOfDate(Date.now(), 'month');
    this.range.end = date.endOfDate(Date.now(), 'month');
    this.host = this.$store.state.global.host;
    this.awal = date.formatDate(this.range.start, 'DD MMM YYYY');
    this.akhir = date.formatDate(this.range.end, 'DD MMM YYYY');
    this.hasil = this.awal + ' - ' + this.akhir;
    this.dateStart = date.formatDate(this.range.start, 'YYYY-MM-DD');
    this.dateEnd = date.formatDate(this.range.end, 'YYYY-MM-DD');
    this.TotalOrder();
    this.TotalPayment();
    this.TotalEP();
    this.getDevice();
  },
  methods: {
    IDRCurrencyFormat: function IDRCurrencyFormat(number) {
      if (number > 999999999) {
        return 'Rp.' + '\xa0' + Math.sign(number) * (Math.abs(number) / 1000000000).toFixed(1) + '\xa0\xa0' + 'M';
      } else {
        var idr = new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
          minimumFractionDigits: 0
        }).format(number);
        return idr;
      }
    },
    TotalOrder: function TotalOrder() {
      var _this = this;

      this.seeLoading = true;
      axios.get(this.host + '/dashboard/analytic/total?paymentType=&from=' + this.dateStart + '&to=' + this.dateEnd + '&deviceImei=' + this.imei, {
        'headers': {
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (res) {
        _this.totalTransactionsOrder = _this.IDRCurrencyFormat(res.data.sales);
        _this.totalSalesOrder = res.data.transactions;
        _this.seeLoading = false;
      });
    },
    TotalPayment: function TotalPayment() {
      var _this2 = this;

      this.seeLoading = true;
      axios.get(this.host + '/dashboard/analytic/total?paymentType=qr&from=' + this.dateStart + '&to=' + this.dateEnd + '&deviceImei=' + this.imei, {
        'headers': {
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (res) {
        _this2.totalTransactionsPayment = _this2.IDRCurrencyFormat(res.data.sales);
        _this2.totalSalesPayment = res.data.transactions;
        _this2.seeLoading = false;
      });
    },
    TotalEP: function TotalEP() {
      var _this3 = this;

      this.seeLoading = true;
      axios.get(this.host + '/dashboard/analytic/total?paymentType=emergency payment&from=' + this.dateStart + '&to=' + this.dateEnd + '&deviceImei=' + this.imei, {
        'headers': {
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (res) {
        _this3.totalTransactionsEP = _this3.IDRCurrencyFormat(res.data.sales);
        _this3.totalSalesEP = res.data.transactions;
        _this3.seeLoading = false;
      });
    },
    downloadExcelOrder: function downloadExcelOrder() {
      var _this4 = this;

      axios.get(this.host + '/dashboard/order/~excel?from=' + this.dateStart + '&to=' + this.dateEnd + '&deviceImei=' + this.imei, {
        'responseType': 'blob',
        'headers': {
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        },
        'Content-Disposition': '"attachment; filename=Riwayat Pengisian.xlsx"',
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }).then(function (res) {
        var url = window.URL.createObjectURL(new Blob([res.data]));
        var link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Riwayat Order_' + _this4.$store.state.global.filter.selectDevice.name + '_' + date.formatDate(_this4.dateStart, 'DDMMYY') + '_' + date.formatDate(_this4.dateEnd, 'DDMMYY') + '.xlsx');
        document.body.appendChild(link);
        link.click();

        _this4.$q.notify({
          color: 'green',
          message: 'Download Success',
          position: 'top-right',
          caption: '',
          multiLine: true
        });
      }).catch(function (err) {
        _this4.$q.notify({
          color: 'red',
          message: 'Error',
          position: 'top-right',
          caption: err,
          multiLine: true
        });

        if (err.response.status === 401) {
          _this4.$cookie.delete('token');

          _this4.$router.push('/login');
        }
      });
    },
    downloadExcelPayment: function downloadExcelPayment() {
      var _this5 = this;

      axios.get(this.host + '/dashboard/payment/~excel?from=' + this.dateStart + '&to=' + this.dateEnd + '&deviceImei=' + this.imei + '&paymentType=' + 'qr', {
        'responseType': 'blob',
        'headers': {
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        },
        'Content-Disposition': '"attachment; filename=Riwayat Pengisian.xlsx"',
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }).then(function (res) {
        var url = window.URL.createObjectURL(new Blob([res.data]));
        var link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Riwayat Pembayaran_QR_' + _this5.$store.state.global.filter.selectDevice.name + '_' + date.formatDate(_this5.dateStart, 'DDMMYY') + '_' + date.formatDate(_this5.dateEnd, 'DDMMYY') + '.xlsx');
        document.body.appendChild(link);
        link.click();

        _this5.$q.notify({
          color: 'green',
          message: 'Download Success',
          position: 'top-right',
          caption: '',
          multiLine: true
        });
      }).catch(function (err) {
        _this5.$q.notify({
          color: 'red',
          message: 'Error',
          position: 'top-right',
          caption: err,
          multiLine: true
        });

        if (err.response.status === 401) {
          _this5.$cookie.delete('token');

          _this5.$router.push('/login');
        }
      });
    },
    downloadExcelEP: function downloadExcelEP() {
      var _this6 = this;

      axios.get(this.host + '/dashboard/payment/~excel?from=' + this.dateStart + '&to=' + this.dateEnd + '&deviceImei=' + this.imei + '&paymentType=' + 'emergency payment', {
        'responseType': 'blob',
        'headers': {
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        },
        'Content-Disposition': '"attachment; filename=Riwayat Pengisian.xlsx"',
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }).then(function (res) {
        var url = window.URL.createObjectURL(new Blob([res.data]));
        var link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Riwayat Pembayaran_CASH_' + _this6.$store.state.global.filter.selectDevice.name + '_' + date.formatDate(_this6.dateStart, 'DDMMYY') + '_' + date.formatDate(_this6.dateEnd, 'DDMMYY') + '.xlsx');
        document.body.appendChild(link);
        link.click();

        _this6.$q.notify({
          color: 'green',
          message: 'Download Success',
          position: 'top-right',
          caption: '',
          multiLine: true
        });
      }).catch(function (err) {
        _this6.$q.notify({
          color: 'red',
          message: 'Error',
          position: 'top-right',
          caption: err,
          multiLine: true
        });

        if (err.response.status === 401) {
          _this6.$cookie.delete('token');

          _this6.$router.push('/login');
        }
      });
    },
    getDevice: function getDevice() {
      var _this7 = this;

      axios.get(this.host + '/dashboard/device/select', {
        'headers': {
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (res) {
        _this7.dataDevice = res.data;

        _this7.dataDevice.sort(function (a, b) {
          return a.name.localeCompare(b.name);
        });

        _this7.dataDevice = [{
          id: '',
          name: 'Semua Device',
          imei: ''
        }].concat(_toConsumableArray(_this7.dataDevice));

        if (_this7.dataDevice.length > 0) {
          if (!_this7.$store.state.global.filter.selectDevice) {
            var selectedDevice = _this7.dataDevice[0];
            _this7.$store.state.global.filter.selectDevice = selectedDevice;
            _this7.imei = selectedDevice.imei;
          } else {
            _this7.imei = _this7.$store.state.global.filter.selectDevice.imei;
          }
        }
      }).catch(function (err) {
        if (err.response.status === 401) {
          _this7.$cookie.delete('token');

          _this7.$router.push('/login');
        }
      });
    },
    getData2: function getData2(x) {
      this.imei = x.imei;
      this.TotalOrder();
      this.TotalPayment();
      this.TotalEP();
    }
  }
};