//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Vue from 'vue';
import VueTour from 'vue-tour';

require('vue-tour/dist/vue-tour.css');

Vue.use(VueTour);
export default {
  name: 'my-tour',
  data: function data() {
    return {
      drawer: false,
      namaUser: localStorage.getItem('name'),
      role: '',
      isFinish: false,
      myOptions: {
        useKeyboardNavigation: true,
        labels: {
          buttonSkip: 'Skip Tour',
          buttonPrevious: 'Previous',
          buttonNext: 'Next',
          buttonStop: 'End'
        }
      },
      myCallbacks: {
        onPreviousStep: this.myCustomPreviousStepCallback,
        onNextStep: this.myCustomNextStepCallback
      },
      steps: [{
        target: '#v-step-0',
        header: {
          title: 'Dashboard Analytic'
        },
        content: 'Analytic Penjualan dan Transaksi harian, bulanan dan tahunan. Jam sibuk setiap device. Ranking untuk setiap device.'
      }, {
        target: '#v-step-1',
        header: {
          title: 'User Setting'
        },
        content: 'List user, menambah user sebagai superadmin dan admin, mengeditnya jika ada perubahan.'
      }, {
        target: '#v-step-2',
        header: {
          title: 'Operator Setting'
        },
        content: 'List operator, daftarkan operator menurut device. Operator memiliki secret PIN sebagai akses untuk login Emergency Payment.'
      }, {
        target: '#v-step-3',
        header: {
          title: 'Device Setting'
        },
        content: 'List device, informasi mengenai device.'
      }, {
        target: '#v-step-4',
        header: {
          title: 'History'
        },
        content: 'Rekap distory penjualan dan transaksi. Ada juga rekap Emergency Payment'
      }, {
        target: '#v-step-5',
        header: {
          title: 'Maintenance'
        },
        content: 'Data QR Payment yang digunakan sebagai data acuan'
      }]
    };
  },
  mounted: function mounted() {
    this.role = localStorage.getItem('role'); // this.start()
  },
  methods: {
    logoutButton: function logoutButton() {
      this.$cookie.delete('token');
      this.$router.push('/login');
    },
    start: function start() {
      this.$tours['myTour'].start();
      console.log('start !');
    },
    myCustomPreviousStepCallback: function myCustomPreviousStepCallback(currentStep) {
      console.log('[Vue Tour] A custom previousStep callback has been called on step ' + (currentStep + 1));
    },
    myCustomNextStepCallback: function myCustomNextStepCallback(currentStep) {
      console.log('[Vue Tour] A custom nextStep callback has been called on step ' + (currentStep + 1));

      if (currentStep === 1) {
        console.log('[Vue Tour] A custom nextStep callback has been called from step 2 to step 3');
      }
    }
  }
};