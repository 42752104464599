import "core-js/modules/es6.math.sign.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Vue from 'vue';
import axios from 'axios';
Vue.component('date-picker');
export default {
  props: {
    filter: {
      type: String
    },
    imei: {
      type: String
    },
    dateStart: {
      type: String
    },
    dateEnd: {
      type: String
    }
  },
  data: function data() {
    return {
      exportDialog: false,
      host: '',
      columns: [{
        name: 'id',
        required: true,
        label: 'Payment ID',
        align: 'center',
        field: function field(row) {
          return row.id;
        },
        format: function format(val) {
          return "".concat(val);
        },
        headerStyle: 'font-weight: 600;',
        headerClasses: 'bg-light-green-2 ellipsis',
        sortable: true
      }, {
        name: 'orderId',
        required: true,
        label: 'Order ID',
        align: 'center',
        field: function field(row) {
          return row.orderId;
        },
        format: function format(val) {
          return "".concat(val);
        },
        headerStyle: 'font-weight: 600;',
        headerClasses: 'bg-light-green-2 ellipsis',
        sortable: true,
        style: 'max-width: 100px'
      }, {
        name: 'paymentType',
        align: 'center',
        label: 'Tipe Pembayaran',
        field: function field(row) {
          return row.paymentType;
        },
        format: function format(val) {
          return "".concat(val);
        },
        headerStyle: 'font-weight: 600;',
        headerClasses: 'bg-light-green-2 ellipsis',
        sortable: true
      }, {
        name: 'provider',
        align: 'center',
        label: 'Provider',
        field: function field(row) {
          return row.provider;
        },
        format: function format(val) {
          return "".concat(val);
        },
        headerStyle: 'font-weight: 600;',
        headerClasses: 'bg-light-green-2 ellipsis',
        sortable: true
      }, {
        name: 'amount',
        align: 'center',
        label: 'Total',
        field: function field(row) {
          return row.amount;
        },
        format: function format(val) {
          return "".concat(val);
        },
        headerStyle: 'font-weight: 600;',
        headerClasses: 'bg-light-green-2 ellipsis',
        sortable: true
      }, {
        name: 'status',
        align: 'center',
        label: 'Status',
        field: function field(row) {
          return row.status;
        },
        format: function format(val) {
          return "".concat(val);
        },
        headerStyle: 'font-weight: 600;',
        headerClasses: 'bg-light-green-2 ellipsis',
        sortable: true
      }, {
        name: 'createdAt',
        align: 'center',
        label: 'Waktu',
        field: function field(row) {
          return row.createdAt;
        },
        format: function format(val) {
          return "".concat(val);
        },
        headerStyle: 'font-weight: 600;',
        headerClasses: 'bg-light-green-2 ellipsis',
        sortable: true
      }],
      data: [],
      loading: false,
      page: 1,
      minPages: 1,
      maxPages: 1
    };
  },
  watch: {
    dateStart: function dateStart() {
      this.getData();
      this.handleInputPage();
    },
    dateEnd: function dateEnd() {
      this.getData();
      this.handleInputPage();
    },
    imei: function imei() {
      this.getData();
      this.handleInputPage();
    },
    filter: function filter() {
      this.getData();
      this.handleInputPage();
    }
  },
  mounted: function mounted() {
    this.host = this.$store.state.global.host;
    this.getData();
  },
  methods: {
    handleInputPage: function handleInputPage(page) {
      this.page = page;
      this.getData();
    },
    IDRCurrencyFormat: function IDRCurrencyFormat(number) {
      if (number > 999999999) {
        return 'Rp.' + '\xa0' + Math.sign(number) * (Math.abs(number) / 1000000000).toFixed(1) + '\xa0\xa0' + 'M';
      } else {
        var idr = new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
          minimumFractionDigits: 0
        }).format(number);
        return idr;
      }
    },
    exportButton: function exportButton() {
      this.exportDialog = true;
    },
    getData: function getData() {
      var _this = this;

      this.loading = true;
      axios.get(this.host + '/dashboard/v1/payment?limit=&page=' + this.page + '&from=' + this.dateStart + '&to=' + this.dateEnd + '&search=' + this.filter + '&deviceImei=' + this.imei, {
        'headers': {
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (res) {
        _this.data = res.data.data;
        _this.page = res.data.meta.page;
        _this.maxPages = res.data.meta.totalPages;
        _this.loading = false;
      }).catch(function (err) {
        _this.loading = false;

        if (err.response.status === 401) {
          _this.$cookie.delete('token');

          _this.$router.push('/login');
        }
      });
    }
  }
};