import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from "./routes";
Vue.use(VueRouter);
import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);
/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default function ()
/* { store, ssrContext } */
{
  var Router = new VueRouter({
    scrollBehavior: function scrollBehavior() {
      return {
        x: 0,
        y: 0
      };
    },
    routes: routes,
    // Leave these as they are and change in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPathw
    mode: process.env.VUE_ROUTER_MODE,
    // mode: 'history',
    base: process.env.VUE_ROUTER_BASE
  });
  return Router;
}