import "core-js/modules/es6.math.sign.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import axios from 'axios';
export default {
  props: {
    imei: {
      type: String
    }
  },
  data: function data() {
    return {
      host: '',
      dataSummarycurrentMonth: {},
      monthcount: 0,
      monthdiff_count: 0.00,
      colorbgsmtc: '',
      colorsmtc: '',
      iconnamemtc: '',
      summarystatmtc: 'Sama',
      isLoading: false
    };
  },
  mounted: function mounted() {
    this.getSummary();
  },
  created: function created() {
    this.host = this.$store.state.global.host;
  },
  watch: {
    imei: function imei() {
      this.getSummary();
    }
  },
  methods: {
    IDRCurrencyFormat: function IDRCurrencyFormat(number) {
      if (number > 999999999) {
        return 'Rp' + '\xa0' + Math.sign(number) * (Math.abs(number) / 1000000000).toFixed(1) + '\xa0\xa0' + 'M';
      } else {
        var idr = new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
          minimumFractionDigits: 0
        }).format(number);
        return idr;
      }
    },
    getSummary: function getSummary() {
      var _this = this;

      this.isLoading = true;
      axios.get(this.host + '/dashboard/analytic?deviceImei=' + this.imei, {
        'headers': {
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (res) {
        _this.dataSummarycurrentMonth = res.data.current_month;
        _this.monthcount = res.data.current_month.count;

        if (res.data.current_month.diff_count === null) {
          _this.monthdiff_count = '-';
          _this.summarystatmtc = '';
          _this.iconnamemtc = '';
          _this.colorsmtc = '';
          _this.colorbgsmtc = '';
        } else {
          _this.monthdiff_count = res.data.current_month.diff_count.toFixed(1);
        }

        if (parseFloat(_this.monthdiff_count) > 0.00) {
          _this.summarystatmtc = 'Naik';
          _this.iconnamemtc = 'trending_up';
          _this.colorsmtc = 'green';
          _this.colorbgsmtc = '#a2e665';
        } else if (parseFloat(_this.monthdiff_count) < 0.00) {
          _this.monthdiff_count = Math.abs(_this.monthdiff_count);
          _this.summarystatmtc = 'Turun';
          _this.iconnamemtc = 'trending_down';
          _this.colorsmtc = 'red';
          _this.colorbgsmtc = 'pink';
        } else if (parseFloat(_this.monthdiff_count) === 0.00) {
          _this.summarystatmtc = 'Sama';
          _this.iconnamemtc = 'drag_handle';
          _this.colorsmtc = 'grey';
          _this.colorbgsmtc = '#d3d3d3';
        }

        _this.isLoading = false;
      }).catch(function (err) {
        if (err.response.status === 401) {
          _this.$cookie.delete('token');

          _this.$router.push('/login');
        }
      });
    }
  }
};