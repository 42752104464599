//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import axios from 'axios';
export default {
  name: 'Monitoring',
  data: function data() {
    return {
      id_active: 0,
      dataActiveTube: [],
      activeTube: '0',
      activeTube_baru: [],
      selectDevice: '',
      dataDevice: [],
      options: ['Google', 'Facebook', 'Twitter', 'Apple', 'Oracle'],
      cookie: '',
      host: '',
      color2: 'red',
      window: {
        width: 0,
        height: 0
      },
      station: 'Lempuyangan',
      model: 'Psi',
      height: '370',
      terheight: {},
      color: 'cyan',
      percent: 0,
      percent2: 100,
      data2: [],
      data3: [],
      cook: '',
      tes: '3',
      getDataInterval: null
    };
  },
  computed: {
    cell: function cell() {
      return 'height:' + this.window.height + 'px;width:' + this.window.width;
    },
    water: function water() {
      return 'z-index:1;position: absolute; left: 0; bottom: 0; width: ' + this.window.width / 10.6 + 'px; height:' + this.percent + '%; padding-top: 50px; border-radius: 50px/25px; background-color: ' + this.color1 + '; transition: 0.3s linear;';
    },
    cylinder: function cylinder() {
      return 'width: ' + this.window.width / 10.6 + 'px; height: ' + this.window.height / 1.7 + 'px; position: relative; overflow: hidden; border-radius: 50px/25px; background-color: #8c9eff';
    },
    gambar: function gambar() {
      return 'z-index:2;position:absolute;top:0%;width:' + this.window.width / 10.6 + 'px; height:' + this.window.height / 1.6 + 'px';
    }
  },
  created: function created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  mounted: function mounted() {
    var _this = this;

    this.host = this.$store.state.global.host;
    this.cookie = this.$cookie.get('token');
    this.getDevice();
    this.getDataInterval = setInterval(function () {
      _this.getDevice();
    }, 60000);
  },
  destroyed: function destroyed() {
    window.removeEventListener('resize', this.handleResize);

    if (this.getDataInterval) {
      clearInterval(this.getDataInterval);
    }
  },
  methods: {
    getActiveTube: function getActiveTube(x) {
      var _this2 = this;

      axios.get(this.host + '/device/' + x.imei + '/active-tube', {
        'headers': {
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (res) {
        _this2.dataActiveTube = res.data;
        _this2.activeTube = _this2.dataActiveTube.activeTube;
        _this2.id_active = _this2.id_active + 1;

        _this2.activeTube_baru.push({
          id: _this2.id_active,
          data: _this2.activeTube
        });

        if (_this2.activeTube_baru[_this2.activeTube_baru.length - 1].data === _this2.activeTube_baru[_this2.activeTube_baru.length - 2].data) {
          if (_this2.activeTube_baru.length > 4) {
            _this2.activeTube_baru = [];
          }
        } else {
          _this2.$q.notify({
            color: 'red',
            message: 'Aktif Tabung : ' + _this2.activeTube,
            position: 'top-right',
            caption: '',
            multiLine: true
          });

          var audio = new Audio('https://aziziqbal.com/got-it-done.mp3');
          audio.play();
        }
      }).catch(function (err) {
        if (err.response.status === 401) {
          _this2.$cookie.delete('token');

          _this2.$router.push('/login');
        }
      });
    },
    handleResize: function handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    getDevice: function getDevice() {
      var _this3 = this;

      axios.get(this.host + '/device/select', {
        'headers': {
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (res) {
        _this3.dataDevice = res.data;

        if (_this3.dataDevice.length > 0) {
          _this3.selectDevice = _this3.dataDevice[0];

          _this3.getActiveTube(_this3.dataDevice[0]);
        } else {
          _this3.activeTube = '0';
        }
      }).catch(function (err) {
        if (err.response.status === 401) {
          _this3.$cookie.delete('token');

          _this3.$router.push('/login');
        }
      });
    }
  }
};