import "core-js/modules/es6.regexp.to-string.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import axios from 'axios';
import swal from 'sweetalert';
import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCDZDcziXpbMoxm-RHtaxzpGLpy4UW_Upg',
    libraries: 'places'
  },
  installComponents: true
});
export default {
  name: 'Device',
  data: function data() {
    return {
      isPwd: false,
      modelAddOperators: {},
      modelPriceSet: {},
      dataPriced: {},
      dataPriced2: {},
      clickedLocation: '',
      dialogAdd: false,
      dialogEdit: false,
      dialogResp: false,
      dialogResp2: false,
      copyStatus: false,
      rawPIN: '',
      namaOPR: '',
      host: '',
      filter: '',
      pagination: {
        rowsPerPage: 10
      },
      loading: true,
      columns: [{
        name: 'id',
        required: true,
        label: 'Operator ID',
        align: 'center',
        field: function field(row) {
          return row.id;
        },
        format: function format(val) {
          return "".concat(val);
        },
        headerStyle: 'font-weight: bold;',
        headerClasses: 'bg-light-green-2 ellipsis',
        sortable: true
      }, {
        name: 'name',
        align: 'left',
        label: 'Nama',
        field: 'name',
        headerStyle: 'font-weight: bold;',
        headerClasses: 'bg-light-green-2 ellipsis',
        sortable: true
      }, {
        name: 'phone',
        align: 'left',
        label: 'Nomor HP',
        field: 'phone',
        headerStyle: 'font-weight: bold;',
        headerClasses: 'bg-light-green-2 ellipsis',
        sortable: true
      }, {
        name: 'devices',
        align: 'left',
        label: 'Lokasi Operasi',
        field: 'devices',
        headerStyle: 'font-weight: bold;',
        headerClasses: 'bg-light-green-2 ellipsis',
        sortable: true
      }, {
        name: 'created_at',
        align: 'left',
        label: 'Waktu',
        field: 'createdAt',
        headerStyle: 'font-weight: bold;',
        headerClasses: 'bg-light-green-2 ellipsis',
        sortable: true
      }, {
        name: 'Action',
        align: 'center',
        headerStyle: 'font-weight: bold;',
        headerClasses: 'bg-light-green-2 ellipsis',
        label: 'Aksi'
      }],
      data2: [],
      data: {},
      selected: {},
      responseData: {},
      location: null,
      latitude: '',
      longitude: '',
      gettingLocation: false,
      errorStr: null,
      getDataInterval: null,
      center: {
        lat: 0,
        lng: 0
      },
      markers: [{
        position: {
          lat: 0,
          lng: 0
        }
      }],
      listdevice: [],
      options: this.listdevice,
      devices: [],
      device: {}
    };
  },
  computed: {
    ConfirmPWD: function ConfirmPWD() {
      var _this = this;

      return [function (v) {
        return !!v || 'Saisissez quelquechose :-)';
      }, function (v) {
        return v !== _this.$refs.fldPasswordChange.value || 'Mots de passe différents';
      }];
    }
  },
  created: function created() {},
  watch: {
    selected: {
      immediate: true,
      deep: true,
      handler: function handler() {}
    }
  },
  mounted: function mounted() {
    var _this2 = this;

    this.host = this.$store.state.global.host;
    this.getData();
    this.getDataInterval = setInterval(function () {
      _this2.getData();
    }, 180000);
    this.getDevice();
  },
  destroyed: function destroyed() {
    if (this.getDataInterval) {
      clearInterval(this.getDataInterval);
    }
  },
  methods: {
    getNameDevice: function getNameDevice(deviceId) {
      var deviceFilter = this.listdevice.filter(function (f) {
        return f.id === deviceId;
      });
      return deviceFilter.length > 0 ? deviceFilter[0].name : 'undefined';
    },
    newPIN: function newPIN() {
      var _this3 = this;

      axios({
        method: 'put',
        url: this.host + '/dashboard/operator/' + this.selected.id + '/pin',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (response) {
        _this3.$q.notify({
          type: 'positive',
          color: 'positive',
          icon: 'check',
          message: 'Success',
          position: 'top-right',
          classes: 'my-custom-font',
          caption: '',
          actions: [{
            label: 'Dismiss',
            color: 'white',
            handler: function handler() {
              /* ... */
            }
          }]
        });

        _this3.dialogEdit = true;

        _this3.onRowClickPin(_this3.selected);
      }).catch(function (error) {
        _this3.$q.loading.hide();

        _this3.$q.notify({
          color: 'negative',
          message: 'Error',
          icon: 'close',
          position: 'top-right',
          caption: error.response.data.message,
          actions: [{
            label: 'Dismiss',
            color: 'white',
            handler: function handler() {
              /* ... */
            }
          }]
        });
      });
    },
    doCopy: function doCopy() {
      var _this4 = this;

      if (this.rawPIN.length > 0) {
        this.$copyText(this.rawPIN).then(function (response) {
          _this4.$q.notify({
            icon: 'announcement',
            message: 'Tersalin',
            progress: true,
            color: 'accent',
            position: 'center',
            textColor: 'white',
            timeout: 1000,
            classes: 'glossy my-custom-font'
          });
        });
        this.copyStatus = true;
      } else {
        this.$q.notify({
          icon: 'announcement',
          message: 'Tidak Tersalin',
          progress: true,
          color: 'negative',
          timeout: 1000,
          position: 'center',
          textColor: 'white',
          classes: 'glossy my-custom-font'
        });
      }
    },
    doCopyEdit: function doCopyEdit() {
      var _this5 = this;

      if (this.selected.pin.length > 0) {
        this.$copyText(this.selected.pin).then(function (response) {
          _this5.$q.notify({
            icon: 'announcement',
            message: 'Tersalin',
            progress: true,
            color: 'accent',
            position: 'center',
            textColor: 'white',
            timeout: 1000,
            classes: 'glossy'
          });
        });
      } else {
        this.$q.notify({
          icon: 'announcement',
          message: 'Tidak Tersalin',
          progress: true,
          color: 'negative',
          timeout: 1000,
          position: 'center',
          textColor: 'white',
          classes: 'glossy'
        });
      }
    },
    checkCopy: function checkCopy() {
      if (!this.copyStatus) {
        this.dialogResp2 = true;
      } else {
        this.dialogResp = false;
      }
    },
    getDevice: function getDevice() {
      var _this6 = this;

      axios.get(this.host + '/dashboard/device/select', {
        'headers': {
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (res) {
        _this6.listdevice = res.data;

        _this6.listdevice.sort(function (a, b) {
          return a.name.localeCompare(b.name);
        });
      }).catch(function (err) {
        if (err.response.status === 401) {
          _this6.$cookie.delete('token');

          _this6.$router.push('/login');
        }
      });
    },
    filterDevice: function filterDevice(val, update) {
      var _this7 = this;

      if (val === '') {
        update(function () {
          _this7.options = _this7.listdevice;
        });
        return;
      }

      update(function () {
        var needle = val.toString().toLowerCase();
        _this7.options = _this7.options.filter(function (v) {
          return v.name.toString().toLowerCase().indexOf(needle) > -1;
        });
      });
    },
    onRowClick: function onRowClick(row) {
      var _this8 = this;

      this.$q.loading.show();
      axios.get(this.host + '/dashboard/operator/' + row.id, {
        'headers': {
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (res) {
        _this8.$q.loading.hide();

        _this8.selected = res.data;
      }).catch(function (err) {
        _this8.$q.loading.hide();

        if (err.response.status === 401) {
          _this8.$cookie.delete('token');

          _this8.$router.push('/login');
        }
      });
      this.dialogEdit = true;
    },
    onRowClickPin: function onRowClickPin(row) {
      var _this9 = this;

      this.$q.loading.show();
      axios.get(this.host + '/dashboard/operator/' + row.id, {
        'headers': {
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (res) {
        _this9.$q.loading.hide();

        _this9.selected.pin = res.data.pin;
      }).catch(function (err) {
        _this9.$q.loading.hide();

        if (err.response.status === 401) {
          _this9.$cookie.delete('token');

          _this9.$router.push('/login');
        }
      });
      this.dialogEdit = true;
    },
    getData: function getData() {
      var _this10 = this;

      axios.get(this.host + '/dashboard/operator', {
        'headers': {
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (res) {
        _this10.data2 = res.data;
        _this10.loading = false;
      }).catch(function (err) {
        _this10.loading = false;

        if (err.response.status === 401) {
          _this10.$cookie.delete('token');

          _this10.$router.push('/login');
        }
      });
    },
    cancle: function cancle() {
      this.dialogAdd = false;
      this.defaultValueAdd();
    },
    defaultValueAdd: function defaultValueAdd() {
      this.modelAddOperators = {};
    },
    save: function save() {
      var _this11 = this;

      this.$q.loading.show();
      axios({
        method: 'post',
        url: this.host + '/dashboard/operator/',
        data: {
          deviceId: this.modelAddOperators.devices.id,
          fullname: this.modelAddOperators.name,
          phone: this.modelAddOperators.phone
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (response) {
        _this11.$q.loading.hide();

        _this11.dialogAdd = false;
        _this11.dialogResp = true;

        _this11.defaultValueAdd();

        _this11.rawPIN = response.data.pin;
        _this11.namaOPR = response.data.fullname;
        _this11.modelAddOperators = {};

        _this11.$q.notify({
          type: 'positive',
          color: 'positive',
          icon: 'check',
          message: 'Success',
          position: 'top-right',
          classes: 'my-custom-font',
          caption: '',
          actions: [{
            label: 'Dismiss',
            color: 'white',
            handler: function handler() {
              /* ... */
            }
          }]
        });

        _this11.getData();
      }).catch(function (error) {
        _this11.$q.loading.hide();

        _this11.$q.notify({
          color: 'negative',
          message: 'Error',
          icon: 'close',
          position: 'top-right',
          caption: error.response.data.message,
          classes: 'my-custom-font',
          actions: [{
            label: 'Dismiss',
            color: 'white',
            handler: function handler() {
              /* ... */
            }
          }]
        });
      });
    },
    update: function update() {
      var _this12 = this;

      this.$q.loading.show();
      axios({
        method: 'put',
        url: this.host + '/dashboard/operator/' + this.selected.id,
        data: {
          id: this.selected.id,
          fullname: this.selected.fullname,
          phone: this.selected.phone,
          deviceId: this.selected.deviceId
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (response) {
        _this12.getData();

        _this12.$q.notify({
          type: 'positive',
          color: 'positive',
          icon: 'check',
          message: 'Success',
          position: 'top-right',
          classes: 'my-custom-font',
          caption: '',
          actions: [{
            label: 'Dismiss',
            color: 'white',
            handler: function handler() {
              /* ... */
            }
          }]
        });

        _this12.dialogEdit = false;

        _this12.$q.loading.hide();
      }).catch(function (error) {
        _this12.$q.loading.hide();

        _this12.$q.notify({
          color: 'negative',
          message: 'Error',
          icon: 'close',
          position: 'top-right',
          caption: error.response.data.message,
          classes: 'my-custom-font',
          actions: [{
            label: 'Dismiss',
            color: 'white',
            handler: function handler() {
              /* ... */
            }
          }]
        });
      });
    },
    deleteUser: function deleteUser(row) {
      var _this13 = this;

      swal({
        title: 'Apakah anda yakin ?',
        text: 'Setelah dihapus, Anda tidak dapat memulihkan data ini !',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
        confirmButtonText: 'Hapus',
        denyButtonText: 'Batal',
        className: 'my-custom-font'
      }).then(function (willDelete) {
        if (willDelete) {
          _this13.destroy(row);
        }
      });
    },
    destroy: function destroy(row) {
      var _this14 = this;

      this.$q.loading.show();
      axios({
        method: 'delete',
        url: this.host + '/dashboard/operator/' + row.id,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (response) {
        _this14.$q.loading.hide();

        _this14.dialogEdit = false;

        _this14.$q.notify({
          type: 'positive',
          color: 'positive',
          icon: 'check',
          message: 'Success',
          position: 'top-right',
          classes: 'my-custom-font',
          caption: '',
          actions: [{
            label: 'Dismiss',
            color: 'white',
            className: 'my-custom-font',
            handler: function handler() {
              /* ... */
            }
          }]
        });

        _this14.getData();
      }).catch(function (error) {
        _this14.$q.loading.hide();

        _this14.$q.notify({
          color: 'negative',
          message: 'Error',
          icon: 'close',
          position: 'top-right',
          className: 'my-custom-font',
          caption: error.response.data.message,
          actions: [{
            label: 'Dismiss',
            color: 'white',
            handler: function handler() {
              /* ... */
            }
          }]
        });
      });
    }
  }
};