import "core-js/modules/es6.array.find-index.js";
import "core-js/modules/es6.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import axios from 'axios';
export default {
  props: {
    dateStart: {
      type: String
    },
    dateEnd: {
      type: String
    }
  },
  data: function data() {
    return {
      host: '',
      seeLoading: false,
      dataPeringkat: [],
      columns: [{
        name: 'index',
        required: true,
        label: 'NO',
        align: 'left',
        field: function field(row) {
          return row.id;
        },
        format: function format(val) {
          return "".concat(val);
        },
        headerStyle: 'font-weight: 600; color: #616161',
        headerClasses: 'bg-light-green-2 ellipsis',
        sortable: true
      }, {
        name: 'device',
        required: true,
        label: 'Nama Terminal',
        align: 'left',
        field: function field(row) {
          return row.name;
        },
        format: function format(val) {
          return "".concat(val);
        },
        headerStyle: 'font-weight: 600; color: #616161;',
        headerClasses: 'bg-light-green-2 ellipsis',
        sortable: true
      }, {
        name: 'sum',
        align: 'left',
        label: 'Total Transaksi',
        field: function field(row) {
          return row.ordersCount;
        },
        format: function format(val) {
          return "".concat(val);
        },
        headerClasses: 'bg-light-green-2 ellipsis',
        headerStyle: 'font-weight: 600; color: #616161',
        sortable: true
      }, {
        name: 'contribute',
        align: 'center',
        label: 'Kontribusi(%)',
        field: function field(row) {
          return row.contribute;
        },
        format: function format(val) {
          return "".concat(val);
        },
        headerClasses: 'bg-light-green-2 ellipsis',
        headerStyle: 'font-weight: 600; color: #616161',
        sortable: true
      }]
    };
  },
  mounted: function mounted() {
    this.getTopOrder();
  },
  created: function created() {
    this.host = this.$store.state.global.host;
  },
  watch: {
    dateStart: function dateStart() {
      this.getTopOrder();
    },
    dateEnd: function dateEnd() {
      this.getTopOrder();
    }
  },
  methods: {
    getTopOrder: function getTopOrder() {
      var _this = this;

      this.seeLoading = true;
      axios.get(this.host + '/dashboard/analytic/top-order?from=' + this.dateStart + '&to=' + this.dateEnd, {
        'headers': {
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (res) {
        _this.dataPeringkat = res.data;
        _this.seeLoading = false;
      });
    },
    getIndex: function getIndex(id) {
      return this.dataPeringkat.findIndex(function (data) {
        return data.id === id;
      });
    }
  }
};