import "core-js/modules/es6.math.sign.js";
import _toConsumableArray from "/app/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.array.fill.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import date from 'quasar/src/utils/date.js';;
import axios from 'axios';
import Vue from 'vue';
import Order from "../components/order.vue";
import MothlyAnalytic1 from "../components/monthAnalyticTotalPenjualan.vue";
import MothlyAnalytic2 from "../components/monthAnalyticTotalTransaksi.vue";
import DailyAnalytic1 from "../components/dayAnalyticTotalPenjualan.vue";
import DailyAnalytic2 from "../components/dayAnalyticTotalTransaksi.vue";
import Total from "../components/totalRanking.vue";
import Transaksi from "../components/transaction.vue";
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import apexchart from 'vue-apexcharts'; // import { dashboardTour } from './../util/driver'

Vue.component('date-picker');
export default {
  name: 'History',
  components: {
    apexchart: apexchart,
    Transaksi: Transaksi,
    Order: Order,
    DailyAnalytic1: DailyAnalytic1,
    DailyAnalytic2: DailyAnalytic2,
    MothlyAnalytic1: MothlyAnalytic1,
    MothlyAnalytic2: MothlyAnalytic2,
    DatePicker: DatePicker,
    Total: Total
  },
  data: function data() {
    return {
      driver: null,
      tab1: 'summary',
      isLoadingMonthlyChart: false,
      isLoadingDailyTraffic: false,
      totalPenjualan: null,
      totalTransaksi: null,
      chartPenjualan: false,
      range: {
        start: new Date(2020, 5, 1),
        end: new Date(2020, 5, 30)
      },
      year: [{
        years: 2020
      }, {
        years: 2021
      }, {
        years: 2022
      }],
      days: ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'],
      tab: '',
      dataTopLeast: false,
      dataChartMon: [{
        name: '',
        data: []
      }],
      dataChartTue: [{
        name: '',
        data: []
      }],
      dataChartWed: [{
        name: '',
        data: []
      }],
      dataChartThu: [{
        name: '',
        data: []
      }],
      dataChartFri: [{
        name: '',
        data: []
      }],
      dataChartSat: [{
        name: '',
        data: []
      }],
      dataChartSun: [{
        name: '',
        data: []
      }],
      dataRevenueOrder: [{
        name: 'Total Penjualan',
        color: '#82C031',
        fontFamily: 'DM Sans',
        data: []
      }, {
        name: 'Total Transaksi',
        color: '#E96E07',
        fontFamily: 'DM Sans',
        data: []
      }],
      chartOptionsTrafic: {
        yaxis: {
          show: false
        },
        chart: {
          type: 'bar',
          toolbar: false
        },
        states: {
          normal: {
            filter: {
              type: 'normal',
              value: 0
            }
          },
          hover: {
            filter: {
              type: 'darken',
              value: 0.4
            }
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 7,
            columnWidth: '70%',
            barHeight: '50%',
            distributed: false,
            rangeBarOverlap: true,
            rangeBarGroupRows: false,
            dataLabels: {
              position: 'top',
              maxItems: 100,
              hideOverflowingLabels: true
            }
          }
        },
        dataLabels: {
          enabled: false,
          formatter: function formatter(val) {
            return val;
          },
          offsetY: -50,
          style: {
            fontSize: '12px',
            colors: ['#304758']
          }
        },
        xaxis: {
          categories: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24']
        },
        fill: {
          colors: []
        },
        grid: {
          show: true,
          borderColor: '#F5F5F5',
          strokeDashArray: 0,
          xaxis: {
            lines: {
              show: false
            }
          },
          yaxis: {
            lines: {
              colors: '#545454',
              show: true
            }
          },
          padding: {
            top: 0,
            right: 25,
            bottom: 0,
            left: 25
          }
        },
        tooltip: {
          enabled: true,
          followCursor: true,
          x: {
            formatter: function formatter(val) {
              return val + ':00';
            }
          },
          y: {
            formatter: function formatter(val) {
              return val + ' Transaksi';
            }
          },
          fixed: {
            enabled: true,
            position: 'topRight',
            offsetX: 10,
            offsetY: 5
          }
        }
      },
      chartOptionsRevenueOrder: {
        chart: {
          type: 'line',
          stacked: false,
          tooltip: {
            enabled: true
          },
          toolbar: false,
          dataLabels: {
            position: 'top'
          }
        },
        dataLabels: {
          enabled: false,
          enabledOnSeries: [0, 1],
          distributed: false,
          style: {
            fontSize: '14px',
            fontFamily: 'DM Sans',
            fontWeight: 'bold',
            colors: undefined
          },
          background: {
            enabled: true,
            foreColor: '#616161',
            padding: 6,
            borderRadius: 2,
            borderWidth: 1,
            borderColor: undefined,
            opacity: 0.8,
            dropShadow: {
              enabled: false,
              top: 1,
              left: 1,
              blur: 1,
              color: '#000',
              opacity: 0.1
            }
          },
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
          }
        },
        stroke: {
          width: [3, 3]
        },
        markers: {
          size: 5
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Ags', 'Sep', 'Okt', 'Nov', 'Des'],
          labels: {
            style: {
              colors: '#010100'
            }
          }
        },
        yaxis: [{
          labels: {
            formatter: function formatter(value) {
              return value / 1000000 + '\xa0' + 'Juta';
            }
          },
          title: {
            text: 'Rupiah',
            style: {
              color: '#010100',
              fontFamily: 'DM Sans',
              fontWeight: 'normal'
            }
          },
          axisTicks: {
            show: true
          },
          axisBorder: {
            show: true,
            color: '#5e5c88'
          }
        }, {
          opposite: true,
          labels: {
            style: {
              colors: '#010100'
            }
          },
          title: {
            text: 'Buah',
            style: {
              fontFamily: 'DM Sans',
              color: '#010100',
              fontWeight: 'normal'
            }
          }
        }],
        tooltip: {
          shared: true,
          intersect: false,
          y: [{
            formatter: function formatter(val) {
              var idr = new Intl.NumberFormat('id-ID', {
                style: 'currency',
                currency: 'IDR',
                minimumFractionDigits: 0
              }).format(val);
              return idr;
            }
          }, {
            formatter: function formatter(val) {
              return val + '\xa0' + 'Buah';
            }
          }]
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          showForNullSeries: true,
          showForZeroSeries: true,
          position: 'bottom',
          horizontalAlign: 'center',
          floating: false,
          fontSize: '16px',
          fontFamily: 'DM Sans',
          fontWeight: 600,
          formatter: function formatter(seriesName, opts) {
            return seriesName;
          },
          inverseOrder: false,
          width: undefined,
          height: 60,
          customLegendItems: [],
          labels: {
            colors: '#ffffff',
            useSeriesColors: '#fff'
          },
          markers: {
            width: 20,
            height: 20,
            strokeWidth: 0,
            strokeColor: '#9E9E9E',
            fillColors: '#616161',
            radius: 10,
            offsetX: -5,
            offsetY: 0
          },
          itemMargin: {
            horizontal: 16,
            vertical: 16
          },
          onItemClick: {
            toggleDataSeries: true
          },
          onItemHover: {
            highlightDataSeries: true
          }
        }
      },
      exportDialog: false,
      selected: {},
      dialogEdit: false,
      host: '',
      filter: '',
      pagination: {
        rowsPerPage: 10
      },
      columns: [{
        name: 'id',
        required: true,
        label: 'ID',
        align: 'center',
        field: function field(row) {
          return row.id;
        },
        format: function format(val) {
          return "".concat(val);
        },
        sortable: true
      }, {
        name: 'deviceImei',
        required: true,
        label: 'Device Imei',
        align: 'center',
        field: function field(row) {
          return row.deviceImei;
        },
        format: function format(val) {
          return "".concat(val);
        },
        sortable: true
      }, {
        name: 'orderNo',
        align: 'center',
        label: 'Order No',
        field: 'orderNo',
        sortable: true
      }, {
        name: 'orderType',
        align: 'center',
        label: 'Order Tipe',
        field: 'orderType',
        sortable: true
      }, {
        name: 'vehicle_type',
        align: 'center',
        label: 'Jenis Kendaraan',
        field: 'vehicleType',
        sortable: true
      }, {
        name: 'wheel_count',
        align: 'center',
        label: 'Jumlah Ban',
        field: 'tireCount',
        sortable: true
      }, {
        name: 'total',
        align: 'center',
        label: 'Total',
        field: 'price',
        sortable: true
      }, {
        name: 'status',
        align: 'center',
        label: 'Status Pembayaran',
        field: 'status',
        sortable: true
      }, {
        name: 'created_at',
        align: 'left',
        label: 'Waktu',
        field: 'createdAt',
        sortable: true
      }],
      data2: [],
      data3: [],
      date: '2019/02/01',
      awal: '',
      akhir: '',
      hasil: '',
      dateStart: '',
      dateEnd: '',
      dataDevice: [],
      dataYears: [],
      dataActiveTube: [],
      activeTube: '0',
      selectDevice: '',
      selectYears: this.$moment().format('YYYY'),
      imei: '',
      trxname: 'hai',
      trxCount: 83984384,
      dataRevenue: [],
      dataOrder: []
    };
  },
  watch: {
    range: function range() {
      this.awal = date.formatDate(this.range.start, 'DD MMMM YYYY');
      this.akhir = date.formatDate(this.range.end, 'DD MMMM YYYY');
      this.hasil = this.awal + ' - ' + this.akhir;
      this.dateStart = date.formatDate(this.range.start, 'YYYY-MM-DD');
      this.dateEnd = date.formatDate(this.range.end, 'YYYY-MM-DD');
    },
    years: function years() {
      this.getSummaryMonthRevenue();
    },
    tab: function tab() {
      if (this.days.indexOf(this.tab) === this.hariSekarang) {
        var jamSekarang = this.jamSekarang;
        console.log(jamSekarang);
        this.chartOptionsTrafic.fill.colors = [function (_ref) {
          var value = _ref.value,
              dataPointIndex = _ref.dataPointIndex,
              w = _ref.w;
          return dataPointIndex === jamSekarang - 1 ? '#FF0000' : '#a2e665';
        }];
      } else {
        this.chartOptionsTrafic.fill.colors = ['#a2e665'];
        this.chartOptionsTrafic.dataLabels.enabled = false;
      }
    }
  },
  created: function created() {
    this.host = this.$store.state.global.host;
  },
  mounted: function mounted() {
    // dashboardTour()
    this.getRevenueOrderByDevice();
    this.getDailyTrafficByDevice();
    this.currentTime();
    this.range.start = date.startOfDate(Date.now(), 'month');
    this.range.end = date.endOfDate(Date.now(), 'month');
    this.awal = date.formatDate(this.range.start, 'DD MMMM YYYY');
    this.akhir = date.formatDate(this.range.end, 'DD MMMM YYYY');
    this.hasil = this.awal + ' - ' + this.akhir;
    this.dateStart = date.formatDate(this.range.start, 'YYYY-MM-DD');
    this.dateEnd = date.formatDate(this.range.end, 'YYYY-MM-DD');
    this.getDevice();
  },
  methods: {
    chartTotalPenjualan: function chartTotalPenjualan() {
      this.chartPenjualan = true;
    },
    downloadExcel: function downloadExcel() {
      var _this = this;

      axios.get(this.host + '/order/~excel', {
        'headers': {
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (res) {
        _this.$q.notify({
          color: 'green',
          message: 'Download Success',
          position: 'top-right',
          caption: '',
          multiLine: true
        });
      }).catch(function (err) {
        _this.$q.notify({
          color: 'red',
          message: 'Error',
          position: 'top-right',
          caption: err,
          multiLine: true
        });

        if (err.response.status === 401) {
          _this.$cookie.delete('token');

          _this.$router.push('/login');
        }
      });
    },
    getRevenueOrderByDevice: function getRevenueOrderByDevice() {
      var _this2 = this;

      this.isLoadingMonthlyChart = true;
      axios.get(this.host + '/dashboard/analytic/monthly-revenue?deviceImei=' + this.imei, {
        'headers': {
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (res) {
        _this2.isLoadingMonthlyChart = false;
        _this2.ReOrdByDevice = res.data;
        _this2.dataRevenue = res.data.map(function (a) {
          return a.revenue;
        });
        _this2.dataOrder = res.data.map(function (a) {
          return a.orders;
        });
        _this2.dataRevenueOrder = [{
          name: 'Total Penjualan',
          color: '#82C031',
          fontFamily: 'DM Sans',
          data: _this2.dataRevenue
        }, {
          name: 'Total Transaksi',
          color: '#E96E07',
          fontFamily: 'DM Sans',
          data: _this2.dataOrder
        }];
      });
    },
    getDailyTrafficByDevice: function getDailyTrafficByDevice() {
      var _this3 = this;

      this.isLoadingDailyTraffic = true;
      axios.get(this.host + '/dashboard/analytic/daily-traffic?deviceImei=' + this.imei, {
        'headers': {
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (res) {
        _this3.isLoadingDailyTraffic = false;
        _this3.dailyTrafficByDevice = res.data;
        _this3.dataChartMon = [{
          name: 'Rata-rata',
          data: _this3.dailyTrafficByDevice[0].data
        }];
        _this3.dataChartTue = [{
          name: 'Rata-rata',
          data: _this3.dailyTrafficByDevice[1].data
        }];
        _this3.dataChartWed = [{
          name: 'Rata-rata',
          data: _this3.dailyTrafficByDevice[2].data
        }];
        _this3.dataChartThu = [{
          name: 'Rata-rata',
          data: _this3.dailyTrafficByDevice[3].data
        }];
        _this3.dataChartFri = [{
          name: 'Rata-rata',
          data: _this3.dailyTrafficByDevice[4].data
        }];
        _this3.dataChartSat = [{
          name: 'Rata-rata',
          data: _this3.dailyTrafficByDevice[5].data
        }];
        _this3.dataChartSun = [{
          name: 'Rata-rata',
          data: _this3.dailyTrafficByDevice[6].data
        }];
      });
    },
    getSummaryMonthRevenue: function getSummaryMonthRevenue() {
      var _this4 = this;

      this.isLoadingMonthlyChart = true;
      axios.get(this.host + '/dashboard/analytic/monthly-revenue?year=' + this.years + '&deviceImei=' + this.imei, {
        'headers': {
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (res) {
        _this4.isLoadingMonthlyChart = false;
        _this4.dataYears = res.data;
        _this4.dataRevenue = res.data.map(function (a) {
          return a.revenue;
        });
        _this4.dataOrder = res.data.map(function (a) {
          return a.orders;
        });
        _this4.dataRevenueOrder = [{
          name: 'Total Penjualan',
          color: '#82C031',
          fontFamily: 'DM Sans',
          data: _this4.dataRevenue
        }, {
          name: 'Total Transaksi',
          color: '#E96E07',
          fontFamily: 'DM Sans',
          data: _this4.dataOrder
        }];
      }).catch(function (err) {
        if (err.response.status === 401) {
          _this4.$cookie.delete('token');

          _this4.$router.push('/login');
        }
      });
    },
    currentTime: function currentTime() {
      this.today = new Date();
      this.hariSekarang = this.today.getDay();
      this.jamSekarang = this.today.getHours();
      this.tab = this.days[this.hariSekarang];
    },
    getDevice: function getDevice() {
      var _this5 = this;

      axios.get(this.host + '/dashboard/device/select', {
        'headers': {
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (res) {
        _this5.dataDevice = res.data;

        _this5.dataDevice.sort(function (a, b) {
          return a.name.localeCompare(b.name);
        });

        _this5.dataDevice = [{
          id: '',
          name: 'Semua Device',
          imei: ''
        }].concat(_toConsumableArray(_this5.dataDevice));

        if (!_this5.$store.state.global.filter.selectDevice) {
          var selectedDevice = _this5.dataDevice[0];
          _this5.$store.state.global.filter.selectDevice = selectedDevice;
          _this5.imei = selectedDevice.imei;
        } else {
          _this5.imei = _this5.$store.state.global.filter.selectDevice.imei;
        }

        _this5.getData2();
      }).catch(function (err) {
        if (err.response.status === 401) {
          _this5.$cookie.delete('token');

          _this5.$router.push('/login');
        }
      });
    },
    IDRCurrencyFormat: function IDRCurrencyFormat(number) {
      if (number > 999999999) {
        return 'Rp' + '\xa0' + Math.sign(number) * (Math.abs(number) / 1000000000).toFixed(1) + '\xa0\xa0' + 'M';
      } else {
        var idr = new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
          minimumFractionDigits: 0
        }).format(number);
        return idr;
      }
    },
    getData2: function getData2(x) {
      this.imei = x.imei;
      this.selectYears = this.$moment().format('YYYY');

      if (this.imei === '') {
        this.dataTopLeast = false;
      } else {
        this.dataTopLeast = true;
      }

      this.getRevenueOrderByDevice();
      this.getDailyTrafficByDevice();
    },
    getMonthly2: function getMonthly2(x) {
      this.years = x.years;
      this.getSummaryMonthRevenue();
    },
    onRowClick: function onRowClick(evt, row) {
      this.selected = row;
      this.dialogEdit = true;
    },
    getData: function getData() {
      var _this6 = this;

      axios.get(this.host + '/dashboard/order?from=' + this.dateStart + '&to=' + this.dateEnd + '&imei=' + this.imei, {
        'headers': {
          'Authorization': 'Bearer ' + this.$cookie.get('token')
        }
      }).then(function (res) {
        _this6.data2 = res.data;

        _this6.data2.map(function (item) {
          item.createdAt = _this6.$moment.utc(item.createdAt).format('DD-MMMM-YYYY HH:mm:ss');

          if (item.price === null) {
            item.price = '-';
          }

          if (item.orderNo === null) {
            item.orderNo = '-';
          }

          if (item.vehicleType === 'car') {
            item.vehicleType = 'Mobil';
          } else {
            item.vehicleType = 'Motor';
          }
        });
      }).catch(function (err) {
        if (err.response.status === 401) {
          _this6.$cookie.delete('token');

          _this6.$router.push('/login');
        }
      });
    }
  }
};