//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import QRPayment from "../components/qrpayment.vue"; // import Prepaid from '../components/prepaid.vue'

export default {
  components: {
    QRPayment: QRPayment // Prepaid

  },
  data: function data() {
    return {
      tab: 'qrpayment'
    };
  }
};